<template>
    <div class="kyc-sumsub">

        <ul class="list-unstyled mt-3 text-center">
            <li>
                <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal" data-target="#kycExplanation">
                    <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                </button>
            </li>
        </ul>

        <!-- FAQ Explanation Modal -->
        <div class="modal fade" id="kycExplanation" tabindex="-1" role="dialog" aria-labelledby="kycExplanation"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="kycExplanationLabel">
                            <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="verificationExplanation"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                trans('common.close')
                            }}
                        </button>
                        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
                    </div>
                </div>
            </div>
        </div>

        <div v-if="validated" class="text-center">
            ✅ {{ trans('common.veriff-sucessfully-submitted') }}
        </div>
        <div v-if="!validated">
            <!-- iframe will be inserted as a child element -->
            <div id="sumsub-websdk-container"></div>
        </div>

        <div v-if="debug">
            <strong>Debug:</strong>
            <button @click="mockSumsubResponse">mock sumsub finish</button>
            <pre>{{ {sumsub, verificationResponse} }}</pre>
            <pre v-if="false">{{ {initKyc, order, email, phone} }}</pre>
        </div>
    </div>
</template>

<script>
import snsWebSdk from '@sumsub/websdk';

export default {
    name: "kyc-sumsub",
    props: ['sumsub', 'order', 'initKyc', 'kycThreshold'],
    inject: ['trans'],
    data() {
        return {
            debug: false,
            verificationResponse: {},
            validated: false,
            applicantId: null,
        }
    },
    mounted() {
        this.initSumsub()
    },
    computed: {
        firstName() {
            return this.initKyc && this.initKyc.FIRST_NAME && String(this.initKyc.FIRST_NAME)
        },
        lastName() {
            return this.initKyc && this.initKyc.LAST_NAME && String(this.initKyc.LAST_NAME)
        },
        phone() {
            const kyc = this.initKyc && this.initKyc.PHONE && String(this.initKyc.PHONE)
            const order = this.order?.user?.phone
            return order ?? kyc
        },
        email() {
            const kyc = this.initKyc && this.initKyc.EMAIL && String(this.initKyc.EMAIL)
            const order = this.order?.user?.email
            return order ?? kyc
        },
        userId() {
            return (this.order && this.order.user_id && String(this.order.user_id)) || undefined
        },
        verificationExplanation() {
            return this.trans('order.kyc-explanation-html')
                .replace(":threshold", this.kycThreshold || 0)
                .replace(":link", this.termsLink)
        },
        termsLink() {
            let link = app.translator.locale + '/terms-and-conditions'
            let title = this.trans('common.terms-and-conditions')
            return `<a href="/${link}" target="_blank">${title}</a>`;
        },
    },
    methods: {
        async initSumsub() {
            const accessToken = await this.getNewAccessToken()
            this.launchWebSdk(accessToken, this.email, this.phone)

        },
        launchWebSdk(accessToken, applicantEmail, applicantPhone) {
            const customI18nMessages = {}
            let snsWebSdkInstance = snsWebSdk.init(
                accessToken,
                // token update callback, must return Promise
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                () => this.getNewAccessToken()
            )
                .withConf({
                    lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
                    email: applicantEmail,
                    phone: applicantPhone,
                    i18n: customI18nMessages, //JSON of custom SDK Translations
                    uiConf: {
                        // customCss: "https://url.com/styles.css"
                        // URL to css file in case you need change it dynamically from the code
                        // the similar setting at Customizations tab will rewrite customCss
                        // you may also use to pass string with plain styles `customCssStr:`
                    },
                })
                .withOptions({addViewportTag: false, adaptIframeHeight: true})
                // see below what kind of messages WebSDK generates
                .on('idCheck.stepCompleted', (payload) => {
                    // console.log('stepCompleted', payload)
                })
                .on('idCheck.onReady', (payload) => {
                    // console.log('onReady', payload)
                })
                .on('idCheck.applicantStatus', this.applicantStatusChanged)
                .on('idCheck.onApplicantLoaded', (payload) => {
                    // console.log('onApplicantLoaded', payload)
                    this.applicantId = payload.applicantId
                })
                .on('idCheck.onError', (error) => {
                    console.error(error)
                })
                .build();

            // you are ready to go:
            // just launch the WebSDK by providing the container element for it
            snsWebSdkInstance.launch('#sumsub-websdk-container')
        },
        applicantStatusChanged(payload) {
            // console.log('applicantStatusChanged', payload)
            this.verificationResponse = payload
            this.updateUserSumsubStatus(payload)
            if (payload.reviewResult?.reviewAnswer === 'GREEN') {
                this.finished()
            }
        },
        getNewAccessToken() {
            return new Promise((resolve, reject) => {
                laravel.ajax.send({
                    method: 'POST',
                    url: this.sumsub.tokenUrl,
                    data: {
                        userId: this.userId,
                        level: this.sumsub.level,
                    },
                    success: (payload) => {
                        // console.log('sumsub generated access token payload', payload)
                        resolve(payload.token)
                    },
                    error: (response) => {
                        laravel.ajax.errorHandler(response)
                        reject()
                    },
                })
            })
        },
        started() {
            this.$emit('started')
        },
        finished() {
            this.validated = true
            this.$emit('finished', this.verificationResponse)
        },
        validate() {
            this.$emit('on-validate', this.$data, this.validated)
            return this.validated
        },
        updateUserSumsubStatus(payload) {
            let data = {
                reviewAnswer: payload.reviewResult?.reviewAnswer,
                reviewStatus: payload.reviewStatus,
                level: payload.levelName,
                applicantId: this.applicantId,
            }
            laravel.ajax.send({
                method: 'POST',
                url: this.sumsub.userStatusUrl,
                data: data,
            })
        },
        mockSumsubResponse() {
            this.verificationResponse = {
                "reviewId": "dyptf",
                "attemptId": "Dqxwp",
                "attemptCnt": 3,
                "elapsedSincePendingMs": 110090,
                "elapsedSinceQueuedMs": 110090,
                "reprocessing": true,
                "levelName": "basic-kyc-level",
                "createDate": "2023-07-06 18:29:25+0000",
                "reviewDate": "2023-07-06 18:31:15+0000",
                "reviewResult": {
                    "reviewAnswer": "GREEN"
                },
                "reviewStatus": "completed",
                "priority": 0
            }
            this.finished();
        }
    }
}
</script>

<style lang="scss">
.kyc-sumsub {

}

#sumsub-websdk-container {
    max-width: 400px;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.sumsub-submit {
    background-color: #007bff;
    cursor: pointer;

    &:hover {
        background-color: #0069d9;
    }
}
</style>
