<template>
    <button type="button"
            @click="onClick"
            v-bind:class="finalBtnClass"
            :value="label"
    >
        <span class="label" v-if="rightSide">
            <slot>{{ label }}</slot>
        </span>
        <span class="icon-container" v-bind:class="{ rightSide: rightSide, leftSide:leftSide }">
            <zoom-y-transition>
                <span v-show="showSpinner" class="icon-holder">
                    <slot name="loadingIcon">
                        <i v-bind:class="spinnerIconClass"></i>
                    </slot>
                </span>
             </zoom-y-transition>
            <zoom-center-transition>
                <span v-show="showActiveIcon" class="icon-holder">
                <slot name="activeIcon">
                    <i v-bind:class="activeIconClass"></i>
                </slot>
                </span>
            </zoom-center-transition>
        </span>
        <span class="label" v-if="leftSide">
            <slot>{{ label }}</slot>
        </span>
    </button>
</template>

<script>
import {ZoomCenterTransition, ZoomYTransition} from 'vue2-transitions'

export default {
    name: 'button-with-spinner',
    props: {
        label: {
            type: String,
            default: 'Submit'
        },
        btnClass: {
            type: [String, Object],
            default: 'btn btn-primary',
        },
        activeIconClass: {
            type: String,
            default: '',
        },
        spinnerIconClass: {
            type: [String, Object],
            default: 'fas fa-spin fa-circle-notch',
        },
        loading: {
            type: Boolean,
            default: false
        },
        leftSide: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    methods: {
        onClick(e) {
            if (!this.loading) {
                this.$emit('click', e)
            }
        }
    },
    computed: {
        finalBtnClass() {
            let defaultClass = {}
            if (typeof this.btnClass == 'string') {
                defaultClass[this.btnClass] = true
            } else {
                defaultClass = this.btnClass
            }

            let merged = Object.assign(defaultClass, {
                'is-loading': this.loading,
                'button-with-spinner': true
            })
            // console.info(merged);
            return merged;
        },
        disabled() {
            return !this.loading;
        },
        showSpinner() {
            return this.loading
        },
        showActiveIcon() {
            return !this.loading && this.activeIconClass !== ''
        },
        rightSide() {
            return !this.leftSide;
        }
    },
    components: {
        ZoomYTransition, ZoomCenterTransition
    }
}
</script>

<style lang="scss">
button.button-with-spinner {

    $height: 1rem;

    .icon-container {
        display: inline-block;
        position: relative;
        width: $height*1.7;
        overflow: visible;
        height: $height;
        margin: 0;

        &.leftSide .icon-holder {
            position: absolute;
            display: block;
            top: $height * -0.30;
            left: 0;
            font-size: 1.3*$height;
        }

        &.rightSide .icon-holder {
            position: absolute;
            display: block;
            top: $height * -0.30;
            right: 0;
            font-size: 1.3*$height;
        }
    }

    &.is-loading {
        .icon-holder {
            top: $height * -0.35;
            font-size: 1.5*$height;
        }
    }

    .label {

    }
}

</style>
