<template>
    <form id="kyc-form" class="order-form-content" action="#" autocomplete="off">

        <ul class="list-unstyled mt-3">
            <li>
                <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal" data-target="#kycExplanation">
                    <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                </button>
            </li>
        </ul>

        <!-- FAQ Explanation Modal -->
        <div class="modal fade" id="kycExplanation" tabindex="-1" role="dialog" aria-labelledby="kycExplanation"
             aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="kycExplanationLabel">
                            <i class="fas fa-question-circle"></i> {{ trans('common.why-we-need-these-data') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="verificationExplanation"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                trans('common.close')
                            }}
                        </button>
                        <!--<button type="button" class="btn btn-primary">Save changes</button>-->
                    </div>
                </div>
            </div>
        </div>

        <div v-for="group in groups" :key="group.name" class="group-container">
            <h4>{{ group.title }}</h4>

            <!-- Optional check-->
            <div class="form-check mb-2" v-if="group.optional">
                <input class="form-check-input" type="checkbox"
                       v-model="group.required"
                       @change="groupChecked(group,$event.target.checked)"
                       :id="group.name">
                <label class="form-check-label" :for="group.name">{{ group.optionalLabel }}</label>
            </div>

            <!--KycField-->
            <collapse-transition :duration="500">
                <div v-show="group.show">
                    <div v-for="type in group.types" :key="type.name" v-bind:data-index="type.order">
                        <kyc-field v-validate="type.rules" :key="'kycField'+type.name"
                                   :error="errors.first(type.name)"
                                   :name="type.name"
                                   :type="type"
                                   :data-vv-as="type.title"
                                   ref="kycField"
                                   :default-value="kycData[type.name]"
                        />
                    </div>
                </div>
            </collapse-transition>
        </div>

        <!-- Approvals -->
        <div class="group-container">
            <!--<h4>Souhlas</h4>-->
            <!--<hr>-->
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="1" id="acceptPrivacy" name="acceptPrivacy"
                       v-model="acceptPrivacy"
                       v-bind:class="{ 'is-invalid': errors.has('acceptPrivacy') }"
                       v-validate="'required'"
                       @change="acceptedPrivacy"
                >
                <label class="form-check-label" for="acceptPrivacy">
                    {{ trans('common.i-agree-with-privacy-before') }} <a v-bind:href="policyLink" target="_blank">
                    {{ trans('common.i-agree-with-privacy') }} </a>
                    {{ trans('common.for-kyc-aml') }}
                </label>
                <div class="invalid-feedback animated fadeIn" v-if="errors.has('acceptPrivacy')">
                    {{ trans('common.validation-privacy-policy') }}
                </div>
            </div>
        </div>


        <div v-if="debug">
            <div>Active: {{ active }}</div>
            <div>Errors: {{ errors }}</div>
            <div>data:
                <pre>{{ kycData }}</pre>
            </div>
            <div>initData:
                <pre>{{ initData }}</pre>
            </div>
            <div v-if="false">Groups:
                <pre>{{ groups }}</pre>
            </div>
        </div>

    </form>
</template>

<script>

import KycField from './KycField.vue'
import {CollapseTransition} from 'vue2-transitions'

export default {
    name: 'kyc-form',
    props: ['typeGroups', 'kycThreshold', 'initData'],
    inject: ['trans'],
    components: {KycField, CollapseTransition},
    data() {
        return {
            active: false,
            mounted: false,
            debug: false,
            groups: {},
            acceptPrivacy: false,
            dateFormat: 'DD.MM.YYYY',
            kycData: {}
        }
    },
    computed: {
        policyLink() {
            return '/' + app.translator.locale + '/privacy-policy'
        },
        verificationExplanation() {
            return this.trans('order.kyc-explanation-html')
                .replace(":threshold", this.kycThreshold)
                .replace(":link", this.termsLink)
        },
        termsLink() {
            let link = app.translator.locale + '/terms-and-conditions'
            let title = this.trans('common.terms-and-conditions')
            return `<a href="/${link}" target="_blank">${title}</a>`;
        }
    },
    created() {
        // console.info('KycForm Created',this.typeGroups);
        for (var group in this.typeGroups) {
            this.$set(this.groups, group, {
                name: group,
                title: this.trans('common.kyc-' + group),
                optionalLabel: this.trans('common.kyc-company-toggle'),
                optional: false,
                show: true,
                required: true,
                types: []
            })
            // type.rules = this.rules(type)
            let order = 0
            for (var typeKey in this.typeGroups[group]) {
                let type = this.typeGroups[group][typeKey]
                let rules = this.getRules(type)
                this.groups[group].types.push(
                    Object.assign({rules: rules, name: typeKey, order: order++}, type)
                )
                // this.$set(this.rules,typeKey, this.getRules(type))
            }
        }
        this.groups.company.optional = true
        this.groups.company.required = false
        this.groups.company.show = false

        this.setInitialData()
        //console.info('KycForm groups',this.groups);
    },
    mounted() {
        let self = this;
        this.mounted = true;
        this.$root.$on('kycFormShown', this.onActivate)
        self.$root.$on('kycField:update', function (type, val) {
            self.kycData[type.name] = val
        })
    },
    methods: {
        setInitialData() {
            if (!(this.initData && Object.keys(this.initData).length)) {
                return
            }
            for (let type in this.initData) {
                this.kycData[type] = this.initData[type]
            }
            const isAlreadyCompany = !!this.initData['COMPANY'] || !!this.initData['ICO']
            this.groups.company.required = isAlreadyCompany
            this.groups.company.show = isAlreadyCompany

            this.acceptPrivacy = !!this.initData['APPROVAL']
            this.kycData['APPROVAL'] = this.acceptPrivacy ? 1 : 0
        },
        onActivate(active) {
            this.active = active
            for (var groupKey in this.groups) {
                let group = this.groups[groupKey]
                if (!active) {
                    group.show = false;
                } else if (group.required || !group.optional) {
                    group.show = true
                }
            }
        },
        validate() {
            let self = this;
            // return true; // TODO! testing only
            let validator = this.$validator.validateAll()
            // this.$emit('on-validate', this.$data, validator)

            return new Promise(function (resolve, reject) {
                validator.then(function (isValid) {

                    if (isValid) {
                        laravel.ajax.send({
                            method: 'post',
                            url: '/api/kyc-store',
                            data: self.kycData,
                            success(p) {
                                resolve(true)
                            },
                            error(e) {
                                let error = laravel.errors.singleErrorMessage(e)
                                reject(error)
                            }
                        })
                    } else {
                        reject(self.trans('common.validation-all-required'))
                        // resolve(isValid)
                    }
                }).catch(function (error) {
                    reject(error)
                })
            })
        },
        field(group, name) {
            let groupO = this.groups[group]
            let field = groupO.types[name]
            if (field) {
                return field
            }
            console.error(`KycField ${group}.${name} has not been found`)
        },
        optionalGroup(group) {
            return group == 'company'
        },
        getRules(type) {
            // return {}
            let rules = {
                required: type.isRequired
            }
            if (type.isDate) {
                rules.date_format = this.dateFormat
            }
            if (type.isImage) {
                rules.is = 'uploaded'
                // rules.image = true
                // rules.extensions = 'jpg,jpeg,png,bmp'
                // rules.size = 5000

            } else if (type.isFile) {
                // rules.size = 5000
                rules.is = 'uploaded'
            }
            return rules
        },
        groupChecked(group, checked) {
            // console.info('groupChecked',group,checked);
            group.show = checked;
            for (var typeKey in group.types) {
                let type = group.types[typeKey]
                type.isRequired = checked
                type.rules = this.getRules(type)
            }
        },
        acceptedPrivacy(event) {
            let type = {id: 30, name: 'APPROVAL'}
            this.$root.$emit('kycField:update', type, event.target.checked ? 1 : 0)
        }
    },
    watch: {
        // wallet(value){ this.$root.$emit('update:tx_id',value) }
    }
}
</script>

<style lang="scss">
#kyc-form {
    .group-container {
        margin: 10px -2.25rem;
        padding: 1rem 2rem;
        /*border-bottom: 1px dashed #EEE;*/
        /*margin-bottom: 20px;*/
        &:nth-child(even) {
            background-color: #f6f6f6;
        }

        .v-select .dropdown-toggle {
            background-color: #FFF;
        }

        @media (max-width: 400px) {
            margin: 10px -1.5rem;

        }

    }
}
</style>
