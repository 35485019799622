import fontawesome from '@fortawesome/fontawesome'
import faUnlockAlt from '@fortawesome/fontawesome-free-solid/faUnlockAlt'
import faLock from '@fortawesome/fontawesome-free-solid/faLock'
import faSignInAlt from '@fortawesome/fontawesome-free-solid/faSignInAlt'
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight'
import faAngleLeft from '@fortawesome/fontawesome-free-solid/faAngleLeft'
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown'
import faChartLine from '@fortawesome/fontawesome-free-solid/faChartLine'
import faBars from '@fortawesome/fontawesome-free-solid/faBars'
import faUserSecrets from '@fortawesome/fontawesome-free-solid/faUserSecret'
import faUserPlus from '@fortawesome/fontawesome-free-solid/faUserPlus'
import faExclamation from '@fortawesome/fontawesome-free-solid/faExclamation'
import faExclamationTriangle from '@fortawesome/fontawesome-free-solid/faExclamationTriangle'
import faCircleNotch from '@fortawesome/fontawesome-free-solid/faCircleNotch'
import faFlag from '@fortawesome/fontawesome-free-solid/faFlag'
import faHandshake from '@fortawesome/fontawesome-free-solid/faHandshake'
import faFrown from '@fortawesome/fontawesome-free-regular/faFrown'
import faWallet from '@fortawesome/fontawesome-free-solid/faWallet'
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope'
import faCalculator from '@fortawesome/fontawesome-free-solid/faCalculator'
import faClipboardCheck from '@fortawesome/fontawesome-free-solid/faClipboardCheck'
import faQuestionCircle from '@fortawesome/fontawesome-free-solid/faQuestionCircle'
import faAddressCard from '@fortawesome/fontawesome-free-solid/faAddressCard'
import faUserCircle from '@fortawesome/fontawesome-free-solid/faUserCircle'
import faUser from '@fortawesome/fontawesome-free-solid/faUser'
import faKey from '@fortawesome/fontawesome-free-solid/faKey'
import faArrowCircleLeft from '@fortawesome/fontawesome-free-solid/faArrowCircleLeft'
import faMoneyCheckAlt from '@fortawesome/fontawesome-free-solid/faMoneyCheckAlt'
import faCalendar from '@fortawesome/fontawesome-free-solid/faCalendar'
import faCloudUploadAlt from '@fortawesome/fontawesome-free-solid/faCloudUploadAlt'
import faInfoCircle from '@fortawesome/fontawesome-free-solid/faInfoCircle'
import faPlusCircle from '@fortawesome/fontawesome-free-solid/faPlusCircle'
import faClipboardList from '@fortawesome/fontawesome-free-solid/faClipboardList'
import faClock from '@fortawesome/fontawesome-free-solid/faClock'
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle'
import faPhone from '@fortawesome/fontawesome-free-solid/faPhone'
import faLifeRing from '@fortawesome/fontawesome-free-solid/faLifeRing'
import farLifeRing from '@fortawesome/fontawesome-free-regular/faLifeRing'
import faSave from '@fortawesome/fontawesome-free-regular/faSave'
import faList from "@fortawesome/fontawesome-free-solid/faList"
import faUniversity from "@fortawesome/fontawesome-free-solid/faUniversity"
import faGlobe from "@fortawesome/fontawesome-free-solid/faGlobe"
import faFacebookSquare from "@fortawesome/fontawesome-free-brands/faFacebookSquare"
import faTwitter from "@fortawesome/fontawesome-free-brands/faTwitter"
import faWhatsup from "@fortawesome/fontawesome-free-brands/faWhatsapp"
import {faExchangeAlt} from "@fortawesome/fontawesome-free-solid";
// import faCheckSquare  from '@fortawesome/fontawesome-free-solid/faCheckSquare'
// import faCreditCard  from '@fortawesome/fontawesome-free-regular/faCreditCard'
// import faSpinner  from '@fortawesome/fontawesome-free-solid/faSpinner'

export default {

    init: (options) => {

        // fontawesome.config = {
        //     familyPrefix: 'fa'
        // }

        fontawesome.library.add(
            faUnlockAlt,
            faLock,
            faSignInAlt,
            faAngleRight,
            faAngleLeft,
            faAngleDown,
            faChartLine,
            faBars,
            faUserPlus,
            faExclamation,
            faExclamationTriangle,
            faCircleNotch,
            faFlag,
            faHandshake,
            faFrown,
            faCalculator,
            faEnvelope,
            faWallet,
            faClipboardCheck,
            faQuestionCircle,
            faAddressCard,
            faUserCircle,
            faList,
            // farUserCircle,
            faUser,
            faKey,
            faMoneyCheckAlt,
            faCalendar,
            faCloudUploadAlt,
            faInfoCircle,
            faPlusCircle,
            faClock,
            faCheckCircle,
            faClipboardList,
            faPhone,
            faLifeRing,
            farLifeRing,
            faSave,
            faArrowCircleLeft,
            faUniversity,
            faGlobe,
            faFacebookSquare,
            faTwitter,
            faWhatsup,
            faExchangeAlt,
            faUserSecrets
            // faCheckSquare,
            // faCreditCard
            // faSpinner
        )

        // fontawesome.dom.i2svg();

    }

}


