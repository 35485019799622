import '../vendor/flot/jquery.flot'
import '../vendor/flot/jquery.flot.tooltip.min'
import '../vendor/flot/jquery.flot.resize'
import '../vendor/flot/jquery.flot.pie'
import '../vendor/flot/jquery.flot.time'
import '../vendor/flot/timezones'
import '../vendor/flot/jquery.flot.stacks'
import '../vendor/flot/jquery.flot.curvedLines'
// var timezoneJS = require('../vendor/flot/timezones');

let graph = {
    $tooltip: null,
    init: function () {

        // console.info(timezoneJS);
        // timezoneJS.timezone.zoneFileBasePath = "tz";
        // timezoneJS.timezone.defaultZoneFile = [];
        // timezoneJS.timezone.init({async: false});

        this.$tooltip = $("<div id='graph-tooltip'></div>").css({
            position: "absolute",
            display: "none",
            // border: "1px solid #fdd",
            padding: "2px",
            "background-color": "#FFF",
            opacity: 0.90
        }).appendTo("body");

        // app.header.onCryptoChangedListeners.push(graph.onCurrencyChanged())

    },
    loadAllGraphs: function () {
        $('.graph').each(function () {
            var $graph = $(this);
            graph.loadGraph($graph)
        });
    },
    loadGraph: function ($graph, after = null) {
        laravel.ajax.send({
            method: 'GET',
            url: $graph.data('url'),
            data: {
                scope: $graph.data('scope'),
                period: $graph.data('period'),
            },
            success: function (data) {
                graph.renderGraph($graph, data)
                if (after !== null) {
                    after($graph, data);
                }
            }
        });
    },
    renderGraph: function ($graph, data) {
        data.series = {
            lines: {
                // lineWidth: 1.5, show: true, fill: 0.5,
                lineWidth: 1.5, show: true, fill: 0.3,
                // lineWidth: 2, show: true, fill: 0.01,
            },
            curvedLines: {
                apply: false,
                active: true,
                monotonicFit: true
            }
        }
        data.shadowSize = 1
        // console.info(data);


        data.grid = {
            show: true,
            // backgroundColor: { colors: ["#000", "#999"] },
            borderWidth: 0,
            borderColor: "#CCC",
            color: '#AAA',
            hoverable: true,
        };

        data.colors = [
            // '#000000',
            '#16b5d9', //gc
            '#97cdd9',
            '#007bff',
            '#597880', //gc2
            '#5c5fab',
            '#18a689',
        ];


        var plot = $graph.plot(data.data, data);

        plot.bind("plothover", function (event, pos, item) {

            if (item) {
                var x = item.datapoint[0].toFixed(2),
                    y = item.datapoint[1].toFixed(2);

                // $("#tooltip").html(item.series.label + " of " + x + " = " + y)
                graph.$tooltip.html(item.series.label + " = " + y + " " + data.yaxis.unit)
                    .css({top: item.pageY + 5, left: item.pageX + 5})
                    .fadeIn(200);
            } else {
                graph.$tooltip.hide();
            }

        });
    }
};

export default graph

