export default {
    init: function () {
        let self = this;

        // console.info({
        //     name:'analytics.init',
        //     gaPresent: this.gaPresent()
        // });

        //bind buttons
        $('body').on('click', '.gaEvent', function (e) {
            let $trigger = $(this);
            let category = $trigger.data('category') || null;
            let action = $trigger.data('action') || null;
            let label = $trigger.data('label') || null;
            self.trackGAEvent(category, action, label)
        })


    },
    trackGAEvent(eventCategory, eventAction, eventLabel) {
        //console.info(['ga','send', 'event', eventCategory, eventAction, eventLabel]);
        if (this.gaPresent()) {
            ga('send', 'event', eventCategory, eventAction, eventLabel)
        }
    },
    gaPresent() {
        return typeof ga !== 'undefined'
    }

}
