<template>
    <form id="order-payment" class="order-form-content" action="">
        <!--  Wallet / BU  -->
        <div class="form-group">
            <label for="wallet" v-html="amountLabel"></label>
            <input name="wallet" type="text" id="wallet" class="form-control" autocomplete="off"
                   :placeholder="walletPlaceholder"
                   v-model="wallet"
                   v-validate="walletRules"
                   v-bind:class="{ 'is-invalid': errors.has('wallet') }"/>
            <div class="invalid-feedback animated fadeIn" v-if="errors.has('wallet')"
                 v-html="isCrypto ? trans('form.validation-wallet') : trans('form.validation-account') "></div>
        </div>

        <!--  Custom Symbols  -->
        <div v-if="!isCrypto">
            <div class="form-check  mt-4">
                <input v-model="customSymbols" class="form-check-input" type="checkbox" value=""
                       id="customSymbols">
                <label class="form-check-label" for="customSymbols">{{ trans('order.show-payment-options') }}</label>
            </div>
            <zoom-y-transition :duration="500">
                <div class="row  mt-2" v-if="customSymbols">
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <input type="text" name="vs" class="form-control mb-3"
                               placeholder="Variabilní symbol" key="vs"
                               v-model.number="vs"
                               v-validate="'numeric'"
                               v-bind:class="{ 'is-invalid': errors.has('vs') }"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                        <input type="text" name="ks" class="form-control mb-3"
                               placeholder="Konstantní symbol" key="ks"
                               v-model.number="ks"
                               v-validate="'numeric'"
                               v-bind:class="{ 'is-invalid': errors.has('ks') }"/>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6">
                        <input type="text" class="form-control mb-3" name="message"
                               placeholder="Zpráva pro příjemce"
                               v-model="message" key="message"/>
                    </div>
                </div>
            </zoom-y-transition>
        </div>


        <!--  FAQ -->
        <div v-else-if="isCrypto">
            <ul class="list-unstyled mt-3">
                <li>
                    <button type="button" class="btn btn-link  m-0 p-0" data-toggle="modal"
                            data-target="#walletExplanation">
                        <i class="fas fa-question-circle"></i> {{ trans('order.where-to-find-this-value') }}
                    </button>
                </li>
                <li v-if="whereToGetWalletUrl"><a v-bind:href="whereToGetWalletUrl" class="btn btn-link  m-0 p-0"
                                                  target="_blank">
                    <i class="fas fa-wallet"></i> {{ trans('order.where-to-get-wallet-label') }}</a>
                </li>
            </ul>
            <!-- Modal -->
            <div class="modal fade" id="walletExplanation" tabindex="-1" role="dialog"
                 aria-labelledby="walletExplanationLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header modal-header-gray">
                            <h5 class="modal-title" id="walletExplanationLabel">
                                <i class="fas fa-question-circle"></i> {{ trans('order.where-to-find-this-value') }}
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" v-html="walletExplanation"></div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                    trans('common.close')
                                }}
                            </button>
                            <!--<button type="button" class="btn btn-primary">Save changes</button>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </form>
</template>

<script>
import {ZoomYTransition} from 'vue2-transitions'

const TYPE_BUY = 1;
const TYPE_SELL = 2;

export default {
    name: 'order-payment',
    props: ['fromCurrency', 'from', 'to', 'toCurrency', 'isCrypto', 'email'],
    inject: ['trans'],
    components: {ZoomYTransition},
    data() {
        return {
            wallet: null,
            vs: null,
            ks: null,
            message: null,
            customSymbols: false,
        }
    },
    computed: {
        isEos: function () {
            return this.isCrypto && this.toCurrency.id.toLowerCase() === "eos"
        },
        walletRules: function () {
            if (this.isEos) {
                return {required: true, max: 12, min: 6, regex: /^([a-z1-5]{12})|([a-z1-5]{1,10}\.[a-z1-5]{1,10})$/}
            }
            return this.isCrypto ? {required: true, regex: /^[a-zA-Z0-9]{24,100}$/} : 'required|min:12|max:34'
        },
        amountLabel() {
            let summary = this.trans('order.summary-amount-sentence-account');
            if (this.isEos) {
                summary = this.trans('order.summary-eos-sentence-wallet');
            } else if (this.isCrypto) {
                summary = this.trans('order.summary-amount-sentence-wallet');
            }
            return summary.replace(':amount', this.to).replace(':symbol', this.toCurrency.symbol)
        },
        walletPlaceholder: function () {
            if (this.isEos) {
                return this.trans('order.wallet-eos-placeholder');
            } else if (this.isCrypto) {
                return this.trans('form.wallet-placeholder');
            }
            return this.trans('form.account-placeholder');
        },
        walletExplanation() {
            if (this.isEos) {
                return this.trans('order.wallet-eos-explanation')
            }
            let explanation = this.trans('order.wallet-explanation')
            let address = '1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2'
            return explanation.replace(':symbol', this.toCurrency.symbol).replace(':address', address)
        },
        whereToGetWalletUrl() {
            return this.trans('order.where-to-get-wallet-url-' + this.toCurrency.symbol.toLowerCase());
        }
    },
    mounted() {
        let self = this;
        self.$root.$on('update:orderType', self.orderTypeChanged)
        self.$root.$on('update:toCurrency', self.orderCurrencyChanged)
    },
    methods: {
        validate() {
            let validation = this.$validator.validateAll()
            let self = this;
            // self.$emit('on-validate', self.$data, isValid)
            return new Promise(function (resolve, reject) {
                validation.then(function (isValid) {
                    if (isValid && !self.isCrypto) {
                        self.validateAccountNumber(resolve, reject)
                    } else {
                        resolve(isValid)
                    }
                }).catch(function (error) {
                    reject(error)
                })
            })

        },
        validateAccountNumber(resolve, reject) {
            let self = this;
            laravel.ajax.send({
                url: '/api/accounts/validate',
                method: 'post',
                data: {
                    email: self.email,
                    number: self.wallet
                },
                success(p) {
                    resolve(true)
                },
                error(e) {
                    reject(laravel.errors.singleErrorMessage(e))
                }
            })
        },
        orderTypeChanged(orderType) {
            this.wallet = null
            if (orderType == TYPE_BUY) {
                this.ks = null;
                this.vs = null;
                this.message = null;
            }
        },
        orderCurrencyChanged(currency) {
            this.wallet = null
        }
    },
    watch: {
        wallet(value) {
            this.$root.$emit('update:wallet', value)
        }
    }
}
</script>
