import preventDefault from './preventDefault'
// import debounce from 'debounce'

let exchangeRates = {
    carousel: null,
    lastUpdate: new Date(),
    init: () => {
        app.graphs.init();

        //Init Carousel
        let options = {
            interval: false
        }
        let carousel = exchangeRates.carousel = $('#carouselERIndicators')
            .carousel(options)
            .on('slide.bs.carousel', (e) => {
                let $slide = $(e.relatedTarget)
                exchangeRates.loadGraph($slide)
            })

        //BIND change period btn
        $(document).on('click', '.btn-graph-period', (e) => {
            let $btn = $(e.target);
            let $graph = exchangeRates.getGraph($btn);
            $graph.data('period', $btn.data('value'));
            exchangeRates.loadGraph($graph)
            app.analytics.trackGAEvent('exchange-rates-graph', 'change-period', $btn.data('value'));
            return preventDefault(e)
        })

        //Load first graph
        exchangeRates.loadGraph(exchangeRates.getActiveSlide())

        app.header.onCryptoChangedListeners.push(exchangeRates.onCurrencyChanged)

        let minute = 60000
        setInterval(function () {
            let now = new Date()
            if (now - exchangeRates.lastUpdate > minute) {
                exchangeRates.loadGraph(exchangeRates.getActiveSlide())
            }
        }, minute / 3)

    },
    getActiveSlide: function () {
        return $('.carousel-item.active', this.carousel)
    },
    /**
     *
     * @param {Currency} currency
     */
    onCurrencyChanged: (currency) => {
        // console.info('exchangeRates.onCurrencyChanged', currency)
        const index = exchangeRates.getSlideIndexFromCurrency(currency)
        if (index !== null) {
            exchangeRates.carousel.carousel(index)
        }
    },
    /**
     * @param {Currency} currency
     * @return {null|number}
     */
    getSlideIndexFromCurrency: (currency) => {
        let slides = exchangeRates.carousel.find(".carousel-item[data-currency='" + currency.symbol + "']")
        if (slides.length && slides[0].dataset) {
            return parseInt(slides[0].dataset.index)
        } else {
            // console.error('exchangeRates.getSlideIndexFromCurrency - slide not found for currency', currency);
        }
        return null
    },
    loadGraph: (el) => {
        let $graph = exchangeRates.getGraph(el)
        $graph.css('opacity', 0);
        app.graphs.loadGraph($graph, exchangeRates.onGraphLoaded);
    },
    onGraphLoaded: ($graph, data) => {
        // console.info($graph,data);
        exchangeRates.updatePeriodState($graph);
        exchangeRates.getGraphItems($graph, '.scope span').first().html(data.titleParts.scope)
        exchangeRates.getGraphItems($graph, '.price span').first().html(data.currentPrice)
        exchangeRates.getGraphItems($graph, '.date span').first().html(data.titleParts.date)
        exchangeRates.getGraphItems($graph, '.period span').first().html(data.titleParts.period)
        $graph.css('opacity', 1);
        exchangeRates.lastUpdate = new Date()
    },
    updatePeriodState: ($graph) => {
        let period = $graph.data('period');
        let $btns = exchangeRates.getGraphItems($graph, '.btn-graph-period')
        for (let btn of $btns) {
            let $btn = $(btn);
            if ($btn.data('value') == period) {
                $btn.addClass('active')
            } else {
                $btn.removeClass('active')
            }
        }
    },
    getGraph: (el) => {
        return $(el).closest('.carousel-item').find('.graph').first()
    },
    getGraphItems: (el, selector = '') => {
        return $(el).closest('.carousel-item').find('.exchange-rates-info ' + selector)
    }

}
export default exchangeRates
