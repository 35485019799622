<script>
export default {
    name: "CookiesSettings",
    props: {
        cookiesDetailsUrl: {
            type: String,
            required: true,
        },
        domain: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            message: "",
            necessary: true,
            analytics: true,
            performanceAndFunctional: true,

            necessaryCollapsed: true,
            analyticsCollapsed: true,
            performanceAndFunctionalCollapsed: true,

            necessaryDetail: false,
            analyticsDetail: false,
            performanceAndFunctionalDetail: false,
        }
    },
    created() {
        this.necessary = true
        this.analytics = [null, true, 'true', '1'].includes(this.$cookies.get('cookies_analytics'))
        this.performanceAndFunctional = [null, true, 'true', '1'].includes(this.$cookies.get('cookies_performance'))
    },
    methods: {
        allowAll() {
            this.setAll(true)
            this.save()
        },
        declineAll() {
            this.setAll(false)
            this.save()
        },
        save() {
            // this.$cookies.remove('cookies_analytics')
            this.$cookies.set('cookies_analytics', this.analytics ? 1 : 0, 365, '/', `.${this.domain}`)

            // this.$cookies.remove('cookies_performance')
            this.$cookies.set('cookies_performance', this.performanceAndFunctional ? 1 : 0, 365, '/', `.${this.domain}`)

            this.$cookies.set('cookies_agreement', 1, 365, '/', `.${this.domain}`)

            this.message = "All Cookies preferences has been saved"
            setTimeout(() => {
                this.message = ""
            }, 5000) // 5 seconds
        },
        setAll(value) {
            this.analytics = value
            this.performanceAndFunctional = value
        },
    },

    computed: {}
}
</script>

<template>
    <div class="cookies-settings">
        <div class="cookies-settings__content">
            <h1>Privacy Preference Center</h1>
            <p>Any web site that you visit may store or retrieve personal information, mostly through the use of
                cookies. The stored or retrieved information might be about you, your preferences or your device and is
                used for the purposes specified per cookies category below. The data controller of your data processed
                through our cookies is DEX GlobalPay. In addition, some cookies we use are from (and controlled by)
                third-party companies, such as, Facebook, Microsoft, Twitter, Youtube, Instagram and Linkedin Analytics
                to provide us with web analytics and intelligence about our sites. You can accept the cookies as per
                your preferences by activating the sliders per cookies category. By accepting cookies, the
                functionalities described per cookies category will be activated and by not accepting cookies, such
                functionalities will not be activated. You can choose not to allow some types of cookies and you have
                the right to withdraw your consent by adapting your preferences in our cookie consent manager. Click on
                the different category headings to find out more and change our default settings. Please read our
                Cookies Policy for more information.</p>


            <h2 class="mt-4 mb-2">Manage Consent Preferences</h2>
            <div class="cookies-settings__message cookies-settings__message--success" v-if="message">
                {{ message }}
            </div>

            <div class="text-right mb-4">
                <button class="btn mr-2" @click="setAll(false)">Deny All</button>
                <button class="btn" @click="setAll(true)">Allow All</button>
            </div>


            <div class="cookies-settings__providers__wrapper">
                <div class="cookies-settings__providers cookies-settings__providers--necessary">
                    <!-- NECESSARY COOKIES -->
                    <div class="cookies-settings__providers__header">
                        <div>
                            <a class="cookies-settings__providers__header-expand" href="#" role="button"
                               @click.prevent="necessaryCollapsed=true" v-show="!necessaryCollapsed">
                                <span class="fas fa-2x fa-angle-down" aria-hidden="true"/>
                                <span class="sr-only">Show description</span>
                            </a>
                            <a class="cookies-settings__providers__header-collapse" href="#" role="button"
                               @click.prevent="necessaryCollapsed=false" v-show="necessaryCollapsed">
                                <span class="fas fa-2x fa-angle-right" aria-hidden="true"/>
                                <span class="sr-only">hide description</span>
                            </a>
                        </div>
                        <h3>Strictly Necessary Cookies</h3>

                        <label for="strictly-necessary">
                            <input type="checkbox" id="strictly-necessary" v-model="necessary" disabled/>
                            {{ necessary ? 'Allowed' : 'Disabled' }}
                        </label>
                    </div>
                    <div class="cookies-settings__providers__description" v-if="!necessaryCollapsed">
                        <p>These cookies are necessary for the website to function and cannot be switched off in our
                            systems. They are usually only set in response to actions made by you which amount to a
                            request for services, such as setting your privacy preferences, logging in or filling in
                            forms. You can set your browser to block or alert you about these cookies, but then some
                            parts of the site will not work.</p>
                        <a :href="cookiesDetailsUrl" target="_blank" @click.prevent="necessaryDetail =!necessaryDetail">Cookies
                            details</a>
                        <div class="cookies-settings__detail" v-show="necessaryDetail">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>laravel_session</td>
                                </tr>
                                <tr>
                                    <th>Provider</th>
                                    <td>DEX-GP</td>
                                </tr>
                                <tr>
                                    <th>Purpose</th>
                                    <td>laravel_session is used for tracking the session. It tracks user data, such as
                                        logged in credentials.
                                    </td>
                                </tr>
                                <tr>
                                    <th>Expiration</th>
                                    <td>1 Day</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>HTTP Cookie</td>
                                </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>XSRF_token</td>
                                </tr>
                                <tr>
                                    <th>Provider</th>
                                    <td>DEX-GP</td>
                                </tr>
                                <tr>
                                    <th>Purpose</th>
                                    <td>XSRF token is used to stop cross-site postscripts, and to set a lifetime on a
                                        form
                                        for security.
                                    </td>
                                </tr>
                                <tr>
                                    <th>Expiration</th>
                                    <td>1 Day</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>HTTP Cookie</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="cookies-settings__providers cookies-settings__providers--analytics">
                    <div class="cookies-settings__providers__header">
                        <!--  First Party Analytics Cookies -->
                        <div>
                            <a class="cookies-settings__providers__header-expand" href="#" role="button"
                               @click.prevent="analyticsCollapsed=true"
                               v-show="!analyticsCollapsed">
                                <span class="fas fa-2x fa-angle-down" aria-hidden="true"/>
                                <span class="sr-only">Show description</span>
                            </a>
                            <a class="cookies-settings__providers__header-collapse" href="#" role="button"
                               @click.prevent="analyticsCollapsed=false"
                               v-show="analyticsCollapsed">
                                <span class="fas fa-2x fa-angle-right" aria-hidden="true"/>
                                <span class="sr-only">hide description</span>
                            </a>
                        </div>
                        <h3>First Party Analytics Cookies</h3>
                        <label for="first-party">
                            <input type="checkbox" id="first-party" v-model="analytics"/>
                            {{ analytics ? 'Allowed' : 'Disabled' }}
                        </label>
                    </div>
                    <div class="cookies-settings__providers__description" v-if="!analyticsCollapsed">
                        <p>These cookies are necessary for the website to function and cannot be switched off in our
                            systems. They are usually only set in response to actions made by you which amount to a
                            request for services, such as setting your privacy preferences, logging in or filling in
                            forms. You can set your browser to block or alert you about these cookies, but then some
                            parts of the site will not work.</p>
                        <a :href="cookiesDetailsUrl" target="_blank"
                           @click.prevent="analyticsDetail =!analyticsDetail">Cookies details</a>
                        <div class="cookies-settings__detail" v-show="analyticsDetail">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>_ga</td>
                                </tr>
                                <tr>
                                    <th>Provider</th>
                                    <td>Google</td>
                                </tr>
                                <tr>
                                    <th>Purpose</th>
                                    <td>This cookie name is associated with Google Universal Analytics - which is a
                                        significant update to Google's more commonly used analytics service. This cookie
                                        is
                                        used to distinguish unique users by assigning a randomly generated number as a
                                        client identifier. It is included in each page request in a site and used to
                                        calculate visitor, session and campaign data for the sites analytics reports.
                                    </td>
                                </tr>
                                <tr>
                                    <th>Expiration</th>
                                    <td>2 years</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>First party</td>
                                </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>_gid</td>
                                </tr>
                                <tr>
                                    <th>Provider</th>
                                    <td>Google</td>
                                </tr>
                                <tr>
                                    <th>Purpose</th>
                                    <td>This cookie is used to distinguish users on your website. It is used to store
                                        information on how visitors use a website and helps in creating an analytics
                                        report
                                        of how the website is doing. The data collected includes the number of visitors,
                                        their source, and the pages visited.
                                    </td>
                                </tr>
                                <tr>
                                    <th>Expiration</th>
                                    <td>1 Day</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>First party</td>
                                </tr>
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>_gat</td>
                                </tr>
                                <tr>
                                    <th>Provider</th>
                                    <td>Google</td>
                                </tr>
                                <tr>
                                    <th>Purpose</th>
                                    <td>This cookie limits the user requests.</td>
                                </tr>
                                <tr>
                                    <th>Expiration</th>
                                    <td>1 minute</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>First party</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="cookies-settings__providers cookies-settings__providers--performance">
                    <div class="cookies-settings__providers__header">
                        <!--  PERFORMANCE AND FUNCTIONAL COOKIES -->
                        <div>
                            <a class="cookies-settings__providers__header-expand" href="#" role="button"
                               @click.prevent="performanceAndFunctionalCollapsed=true"
                               v-show="!performanceAndFunctionalCollapsed">
                                <span class="fas fa-2x fa-angle-down" aria-hidden="true"/>
                                <span class="sr-only">Show description</span>
                            </a>
                            <a class="cookies-settings__providers__header-collapse" href="#" role="button"
                               @click.prevent="performanceAndFunctionalCollapsed=false"
                               v-show="performanceAndFunctionalCollapsed">
                                <span class="fas fa-2x fa-angle-right" aria-hidden="true"/>
                                <span class="sr-only">hide description</span>
                            </a>
                        </div>
                        <h3>Performance and Functional Cookies</h3>
                        <label for="performance">
                            <input type="checkbox" id="performance" v-model="performanceAndFunctional"/>
                            {{ performanceAndFunctional ? 'Allowed' : 'Disabled' }}
                        </label>
                    </div>
                    <div class="cookies-settings__providers__description" v-if="!performanceAndFunctionalCollapsed">
                        <p>Performance cookies allow us to count visits and traffic sources so we can measure and
                            improve the performance of our site. They help us to know which pages are the most and least
                            popular and see how visitors move around the site. If you do not allow these cookies we will
                            not know when you have visited our site, and will not be able to monitor its performance.
                            These cookies don't collect information that identifies a visitor. All information these
                            cookies collect is anonymous and is only used to improve how the website works. Third party
                            vendors may have access to this data and may use it to improve their overall services and
                            offerings.</p>
                        <p>Functional cookies allow a site to remember choices you make (such as your user name,
                            language or the region you are in) and provide more enhanced, personal features. These
                            cookies cannot track your browsing activity on other websites. They don’t gather any
                            information about you that could be used for advertising or remembering where you’ve been on
                            the Internet outside our site. These cookies don't collect information that identifies a
                            visitor. All information these cookies collect is anonymous and is only used to improve how
                            the website works. Third party vendors may have access to this data and may use it to
                            improve their overall services and offerings. </p>
                        <a :href="cookiesDetailsUrl" target="_blank">Cookies details</a>
                        <div class="cookies-settings__detail" v-show="performanceAndFunctionalDetail">
                        </div>
                    </div>
                </div>
            </div>


            <div class="cookies-settings__buttons">
                <button class="btn btn-warning " @click="declineAll">Decline all</button>
                <button class="btn btn-primary" @click="allowAll">Allow all</button>
                <button class="btn btn-primary" @click="save">Save User Settings</button>
            </div>

            <div class="cookies-settings__message cookies-settings__message--success" v-if="message && false">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.cookies-settings {
    &__message {
        padding: 1rem;

        &--success {
            background-color: #d4edda;
            border: 1px solid #c3e6cb;
            color: #155724;
            margin: 1rem 0;
        }
    }

    &__providers {
        border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        h3 {
            font-size: 1rem;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }

        &__header {
            display: flex;
            flex-flow: row nowrap;
            gap: 2rem;

            h3 {
                flex: 1 1 auto;
            }

            label {
                cursor: pointer;
                display: flex;
                flex-flow: row nowrap;
                gap: 0.5rem;

                input {
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }
        }

        &__description {
            margin-top: 1rem;

            table {
                min-width: 50vw;
                border-collapse: collapse;
                border: 1px solid #ccc;
                margin: 1rem 0;

                td, th {
                    border: 1px solid #ccc;
                    margin: 0;
                    padding: 0.5rem;
                }

                th {
                    background-color: #eee;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
