<template>

    <div class="card">
        <h1>Parent Test comm</h1>

        <button-with-spinner
            @click="btnClicked"
            :loading="loading"
            :label="loading ? 'loading': 'Odeslat'"
            active-icon-class="fas fa-handshake"

        >
        </button-with-spinner>

        <br>


        <div>loading: {{ loading ? 'loading' : 'Odeslat' }}</div>

        <div class="mb-2">
            initProp:
            <input type="text" v-model="initProp">
            <strong>{{ initProp }}</strong>
        </div>
        <div class="mb-2">
            var:
            <input type="text" v-model="variable">
            <strong> {{ variable }}</strong>
        </div>
        <div class="mb-2">
            prop:
            <input type="text" v-model="property">
            <strong>{{ property }}</strong>
        </div>
        <div class="mb-2">
            synced:
            <input type="text" v-model="synced">
            <strong>{{ synced }}</strong>
        </div>
        <div class="mb-2">
            translation: <strong>{{ trans('common.terms-and-conditions') }}</strong>
        </div>


        <div class="card-body">
            <transition appear name="fade">
                <test-comm-child
                    :init-prop="initProp"
                    :init-variable="variable"
                    :property="property"
                    :synced.sync="synced"
                    @test-event="onTestEvent"
                />
            </transition>


            <h2>Test transition group</h2>

            Count <input type="text" v-model.number="count">
            <button @click="showList = !showList">Show list</button>

            <div v-if="showList">
                <div v-for="(item,i) in list">
                    <transition-group appear v-bind:css="false"
                                      v-on:before-enter="beforeEnter"
                                      v-on:enter="enter"
                                      v-on:leave="leave">
                        <div class="list-item" :key="item" v-bind:data-index="i">{{ "i".repeat(i) }}</div>
                    </transition-group>
                </div>
            </div>
        </div>


    </div>
</template>
<script>


import TestCommChild from "./TestCommChild.vue";
import ButtonWithSpinner from "./ButtonWithSpinner.vue";
import {TweenLite} from 'gsap';

export default {
    components: {
        ButtonWithSpinner,
        TestCommChild
    },
    name: 'test-comm',
    props: ['initProp'],
    provide() {
        return {trans: this.trans}
    },
    data() {
        return {
            variable: 'variable',
            property: this.initProp,
            synced: null,
            loading: false,
            count: 10,
            lastIndex: 0,
            showList: false
        }
    },
    computed: {
        list() {
            let list = [];
            for (var i = 0; i < this.count; i++) {
                list.push(this.lastIndex + i)
            }
            this.lastIndex = this.lastIndex + this.count
            return list;
        }
    },
    created() {
        this.synced = this.initProp
        // console.info(app.translator);
    },
    mounted() {
        this.$root.$on('test-event', (data) => {
            console.info('$root.on(test-event)', data)
        })
    },
    methods: {
        trans(term) {
            return app.translator.trans(term)
        },
        btnClicked() {
            let self = this
            self.loading = true
            console.info('Btn clicked');
            setTimeout(function () {
                self.loading = false
            }, 1500)
        },
        beforeEnter: function (el) {
            el.style.display = 'block'
            el.style.opacity = 0
            el.style.height = '0px'
        },
        enter: function (el, done) {
            var delay = el.dataset.index / 10
            TweenLite.to(
                el, 1,
                {opacity: 1, height: '20px', delay: delay, onComplete: done}
            )
        },
        leave: function (el, done) {
            var delay = el.dataset.index / 10
            TweenLite.to(
                el, 1,
                {opacity: 0, height: '0px', delay: delay, onComplete: done}
            )
        },
        onTestEvent(data) {
            console.info('parent.onTestEvent', data)
        }
    }

}

</script>

<style>

</style>
