<template>
    <div id="account-register">

        <!-- Create Account Modal -->
        <div class="modal fade" id="createAccountModal" data-show="false" data-backdrop="static" tabindex="-1"
             role="dialog" aria-labelledby="createAccountModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="createAccountModalLabel">{{ trans('common.account-add') }}</h5>
                        <button type="button" class="close" :aria-label="trans('common.close')" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="opened">
                        <form action="">
                            <div class="form-group zoomIn animation-delay-1">
                                <label for="number">{{ trans('form.account-placeholder') }}</label>
                                <input type="text" class="form-control" name="number" id="number"
                                       :placeholder="trans('common.bank_account_number')"
                                       v-model="number" v-validate="rules" v-bind:class="{ 'is-invalid': isInvalid}"
                                >
                                <div class="invalid-feedback animated fadeIn" v-html="errorMessage"></div>
                                <!--{{ errors }}-->
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer zoomIn animation-delay-2">
                        <button v-if="false" type="button" class="btn btn-secondary" data-dismiss="modal">
                            {{ trans('common.close') }}
                        </button>
                        <button-with-spinner btnClass="btn btn-primary animated flipInX animation-delay-1  mt-3"
                                             :label="trans('common.account-add')"
                                             activeIconClass="fas fa-check-circle"
                                             :loading="loading"
                                             @click="createAccount"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- Trigger -->
        <button-with-spinner btnClass="btn btn-info flipInX animated mt-3" data-target="#createAccountModal"
                             :label="trans('common.account-add')"
                             activeIconClass="fas fa-plus-circle"
                             :loading="opened || loading"
                             @click="openModal"
        />

    </div>
</template>

<script>
import ButtonWithSpinner from './ButtonWithSpinner.vue'

export default {
    name: "account-register",
    components: {ButtonWithSpinner},
    inject: ['trans'],
    data() {
        return {
            number: null,
            opened: false,
            loading: false,
            apiError: false,
            $modal: null,
        }
    },
    mounted() {
        this.$modal = $('#createAccountModal').modal()
        this.$modal.on('hidden.bs.modal', this.closeModal.bind(this))
    },
    computed: {
        rules() {
            if (this.opened) {
                return 'required|min:12|max:34'
            }
            return '';
        },
        isInvalid() {
            return this.apiError != false || this.errors.has('number')
        },
        errorMessage() {
            return this.apiError ? this.apiError : this.trans('form.validation-account');
        }
    },
    methods: {
        createAccount() {
            let self = this;
            let validation = this.$validator.validateAll()

            validation.then(function (isValid) {
                if (!isValid) {
                    return
                }
                self.apiError = false
                self.loading = true

                laravel.ajax.send({
                    method: 'post',
                    url: '/api/accounts/store',
                    data: {'number': self.number},
                    success(p) {
                        self.closeModal()
                        self.loading = false
                        self.number = null
                        // self.createAccountNumber = null;
                        self.$emit('account:registered', p)
                    },
                    error(e) {
                        self.apiError = laravel.errors.singleErrorMessage(e)
                        self.loading = false
                    }

                })
            }).catch(function (error) {

            })

        },
        openModal() {
            this.$modal.modal('show')
            this.opened = true;
        },
        closeModal() {
            this.$modal.modal('hide')
            this.opened = false
        }
    }
}
</script>

<style lang="scss" scoped>
#account-register {
    @media (max-width: 350px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
}
</style>
