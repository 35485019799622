import innerHeight from './../vendor/iosInnerHeight'

export default {
    init: (options) => {

        function getHeight() {
            return innerHeight();
            if ($('body').hasClass('fullscreen')) {
                return $(window).innerHeight();
            }
            return Math.max($('header').height() + $('nav').height(), 250)
        }

        function getMinSquareDist(w, h) {
            return (w + h) * 4;
        }

        function drawBg(alpha = 1) {
            ctx.fillStyle = `rgba(0,0,0, ${alpha})`;
            ctx.fillRect( 0, 0, w, h );

            return;
            // ctx.clearRect(0, 0, w,h);

            let origAlpha = ctx.globalAlpha;
            ctx.globalAlpha = alpha
            ctx.drawImage(img, 0, 0, w, h);
            ctx.globalAlpha = origAlpha
        }

        var c = document.getElementById('body-effect'),
            w = c.width = $(window).width(),
            h = c.height = getHeight(),
            ctx = c.getContext('2d'),
            // color = "rgba(100, 255, 255, 0.3)",
            color = "rgb(133,198,218,0.1)",
            particles = [],
            minSquareDist = getMinSquareDist(w, h),
            springConst = .000025,
            tick = 0,
            // particleCount = options.isMobile ? 20 : 80;
            particleCount = options.isMobile ? 20 : 80;

        let $img = $('<img src="/front/bg.jpg">')
        let img = $img[0];


        for (var i = 0; i < particleCount; ++i)
            particles.push(new Particle);

        function Particle() {

            this.x = Math.random() * w;
            this.y = Math.random() * h;

            var rad = Math.random() * Math.PI * 2;

            this.vx = Math.cos(rad);
            this.vy = Math.sin(rad);

            this.waveSize = rad;
        }

        Particle.prototype.update = function () {

            this.x += this.vx;
            this.y += this.vy;

            var changeX = true,
                changeY = true;

            if (this.x < 0)
                this.x = 0;
            else if (this.x > w)
                this.x = w;
            else changeX = false;

            if (changeX)
                this.vx *= -1;

            if (this.y < 0)
                this.y = 0;
            else if (this.y > h)
                this.y = h;
            else changeY = false;

            if (changeY)
                this.vy *= -1;

            // this.waveSize += .1 + Math.random() * .001;
        }
        Particle.prototype.render = function () {

            // ctx.fillStyle = 'hsl(hue,80%,70%)'.replace( 'hue', this.x / w * 360 + tick )
            ctx.fillStyle = color
            ctx.beginPath();
            // ctx.arc( this.x, this.y, 2 + 2 * Math.sin( this.waveSize ), 0, Math.PI * 2 );
            ctx.fillRect(this.x - 1, this.y - 1, 2, 2)
            ctx.fill();
        }


        function anim() {

            window.requestAnimationFrame(anim);

            ++tick;

            let sin = Math.sin(tick / 10);
            sin = (1-Math.abs(sin)) * 0.02
            // console.info(sin);
            drawBg(sin);
            // console.info( Math.floor(sin*10)/10 );

            ctx.strokeStyle = color

            particles.map(function (particle) {
                if (Math.random() > 0.7) {
                    particle.update();
                }
            });

            for (var i = 0; i < particles.length; ++i) {

                var p1 = particles[i];

                for (var j = i + 1; j < particles.length; ++j) {

                    var p2 = particles[j],
                        dx = p1.x - p2.x,
                        dy = p1.y - p2.y,
                        dSquare = dx * dx + dy * dy;

                    if (Math.random() > 0.8) {
                        if (dSquare < minSquareDist) {

                            p1.vx -= dx * springConst;
                            p1.vy -= dy * springConst;
                            p2.vx += dx * springConst;
                            p2.vy += dy * springConst;

                            ctx.lineWidth = (1 - dSquare / minSquareDist) * 2;

                            ctx.beginPath();
                            ctx.moveTo(p1.x, p1.y);
                            ctx.lineTo(p2.x, p2.y);
                            ctx.stroke();
                        }
                    }
                }

                p1.render();
            }

        }

        $img.on('load', function () {
            drawBg(1)
            anim();
        })


        window.addEventListener('resize', function () {


            drawBg(1);
            w = c.width = $(window).width();
            h = c.height = getHeight()

            minSquareDist = getMinSquareDist(w, h)
        })


    }
}
