<template>
    <div id="accounts-step" class="order-form-content">

        <p v-html="trans('order.we-need-know-bank-accounts')"></p>
        <p class="text-muted" v-if="false"><i class="fas fa-info-circle"></i> <span
            v-html="trans('common.account-verification-label')"></span></p>

        <div>
            <account-form v-for="account in accounts"
                          :key="account.id"
                          :account="account"
                          :verification-required="verificationRequired"
                          ref="accounts"
                          v-on:account:update="accountUpdated(account, $event)"
            />
        </div>

        <input type="hidden" name="verified" :value="verified" v-validate="verificationRules">
        <input type="hidden" name="accounts" :value="accounts.length" v-validate="accountCountRules">

        <zoom-y-transition :duration="500">
            <div class="alert alert-danger mt-3 mb-0" role="alert" v-if="errorMessage != false">
                <i class="far fa-frown"></i> {{ errorMessage }}
            </div>
        </zoom-y-transition>
        <!--{{ errors }}-->

        <account-register
            ref="accountRegister"
            v-if="canCreateAccount"
            v-on:account:registered="accountRegistered"
        />

        <!--  FAQ -->
        <div class="mt-3">
            <ul class="list-unstyled mt-2">
                <li>
                    <button type="button" class="btn btn-link m-0 p-0" data-toggle="modal"
                            data-target="#verificationExplanation">
                        <i class="fas fa-question-circle"></i> {{ trans('order.why-we-need-account-verification') }}
                    </button>
                </li>
            </ul>
            <!-- Modal -->
            <div class="modal fade" id="verificationExplanation" tabindex="-1" role="dialog"
                 aria-labelledby="verificationExplanationLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header modal-header-gray">
                            <h5 class="modal-title" id="verificationExplanationLabel">
                                <i class="fas fa-question-circle"></i>
                                {{ trans('order.why-we-need-account-verification') }}
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body" v-if="verificationRequired" v-html="verificationExplanation"></div>
                        <div class="modal-body" v-else-if="!verificationRequired">
                            <p>{{ trans('common.account-verification-optional') }}</p>
                            <ul class="list-unstyled mt-2">
                                <li class=""><i class="fas fa-info-circle"></i>
                                    {{ trans('common.account-verification-blur') }}
                                </li>
                                <li class=""><i class="fas fa-plus-circle"></i>
                                    {{ trans('common.accounts-can-be-edited-later') }}
                                </li>
                            </ul>
                        </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                    trans('common.close')
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ZoomYTransition} from 'vue2-transitions'
// import ButtonWithSpinner from './ButtonWithSpinner.vue'
import AccountForm from "./AccountForm.vue";
import AccountRegister from "./AccountRegister.vue";

export default {
    name: 'accounts-step',
    props: ['user', 'kycThreshold', 'verificationRequired'],
    inject: ['trans'],
    components: {AccountForm, AccountRegister, ZoomYTransition},
    data() {
        return {
            accounts: [],
            canCreateAccount: true,
        }
    },
    computed: {
        locale() {
            return app.translator.locale
        },
        verificationExplanation() {
            return this.trans('order.account-verification-explanation-html')
                .replace(":threshold", this.kycThreshold)
                .replace(":link", this.termsLink)
        },
        termsLink() {
            let link = this.locale + '/terms-and-conditions'
            let title = this.trans('common.terms-and-conditions')
            return `<a href="/${link}" target="_blank">${title}</a>`;
        },
        verified() {
            if (typeof this.accounts === 'undefined') {
                return false;
            }
            let verified = true;
            for (let account of this.accounts) {
                verified = verified && account.verified
            }
            return verified ? 'verified' : false;
        },
        verificationRules() {
            if (this.verificationRequired) {
                return {is: 'verified', required: true}
            }
            return {required: true}
        },
        accountCountRules() {
            if (this.verificationRequired) {
                return {min_value: 1, required: true}
            }
            return {min_value: 0, required: true}
        },
        errorMessage() {
            if (this.errors.has('accounts')) {
                return this.trans('common.validate-account-count')
            }
            if (this.errors.has('verified')) {
                return this.trans('common.validate-account-verification')
            }
            return false
        }

    },
    created() {
        this.fetchAccounts()
    },
    methods: {
        validate() {
            let isValid = this.$validator.validateAll()
            //this.$emit('on-validate', this.$data, isValid)
            for (let account of (this.$refs.accounts ?? [])) {
                account.validate()
            }
            return isValid
        },
        fetchAccounts() {
            let self = this;
            laravel.ajax.send({
                url: '/api/accounts/',
                success(p) {
                    self.accounts = p
                }
            })
        },
        accountRegistered(account) {
            app.analytics.trackGAEvent('account-form', 'created', 'accountId ' + account.id)
            this.accounts.push(account)
        },
        accountUpdated(account, data) {
            app.util.merge(account, data)
            if (account.verified) {
                app.analytics.trackGAEvent('account-form', 'verified', 'accountId ' + account.id)
            } else {
                app.analytics.trackGAEvent('account-form', 'updated', 'accountId ' + account.id)
            }

        }

    },
    watch: {}
}
</script>


<style lang="scss">
$gocoin: #5E57EC;
$gocoin2: #9747DC;
$gray: #ebebeb;
$red: #dc3545 !default;
$blue: #007bff !default;
.file-group {
    padding-top: 10px;
    /*border-top: 1px solid #ddd;*/
    .example {
        width: 100%;
    }

}

</style>
