export default {
    init: function () {

        function updateTransparency() {
            var top = $(window).scrollTop();
            if (!navbarHasBg && top > 40) {
                $navbar.addClass('navbar-dex-bg');
                navbarHasBg = true;
            } else if (navbarHasBg && top < 10) {
                $navbar.removeClass('navbar-dex-bg');
                navbarHasBg = false;
            }
        }

        var $navbar = $('nav');
        var navbarHasBg = $navbar.hasClass('navbar-dex-bg');
        window.addEventListener("scroll", (e) => {
            updateTransparency()
        });
        updateTransparency()


        $navbar.on('click', '.navbar-toggler', (e) => {
            let $toggle = $(e.currentTarget);
            if (!$toggle.hasClass('collapsed')) {
                $navbar.addClass('navbar-dex-bg');
                $navbar.removeClass('is-active');
                $toggle.removeClass('is-active')
                navbarHasBg = true;
            } else {
                $toggle.addClass('is-active')
                $navbar.addClass('is-active');
            }
        })


    }
}
