<template>
    <form id="order-user" class="order-form-content" action="">


        <!-- Modal -->
        <div class="modal fade" id="registrationExplanation" tabindex="-1" role="dialog"
             aria-labelledby="registrationExplanation" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-header-gray">
                        <h5 class="modal-title" id="walletExplanationLabel">
                            <i class="fas fa-question-circle"></i> {{ trans('common.registration-benefits-button') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-html="trans('order.registration-benefits-html')"></div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal">{{
                                trans('common.close')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <p v-html="trans('common.user-has-not-been-registered').replace(':email',email)"></p>

        <div class="form-group row" v-if="false">
            <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-10">
                <input type="text" readonly class="form-control-plaintext" id="staticEmail" v-bind:value="email">
            </div>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="1" id="register"
                   v-model="register">
            <label class="form-check-label" for="register">{{ trans('common.i-want-to-register') }}</label>
        </div>

        <slide-y-up-transition :duration="500">
            <div class="form-row" v-if="register">
                <div class="col-sm-12 col-md-6 mt-3">
                    <input type="password" name="password" class="form-control" :placeholder="trans('common.password')"
                           ref="password"
                           v-model="password" v-validate="{ required: register, min:6 }"
                           v-bind:class="{ 'is-invalid' : errors.has('password') }"
                    >
                </div>
                <div class="col-sm-12 col-md-6 mt-3">
                    <input type="password" name="password_confirmed" class="form-control"
                           :placeholder="trans('common.password-confirmation')"
                           v-model="password_confirmed" v-validate="{ required: register, confirmed: 'password' }"
                           v-bind:class="{ 'is-invalid' : errors.has('password_confirmed') }"
                    >
                </div>
            </div>
        </slide-y-up-transition>

        <slide-y-down-transition :duration="500">
            <small id="emailHelp" class="form-text text-muted"
                   v-if="register">{{ trans('common.registration-explain') }}</small>
        </slide-y-down-transition>

        <button class="btn btn-link mt-3 mb-4 ml-0 pl-0" type="button" data-toggle="modal"
                data-target="#registrationExplanation">
            <i class="fas fa-question-circle"></i> {{ trans('common.registration-benefits-button') }}
        </button>


    </form>
</template>

<script>
import {SlideYDownTransition, SlideYUpTransition} from 'vue2-transitions'

export default {
    name: 'order-user',
    props: ['loggedEmail', 'user'],
    inject: ['trans'],
    components: {SlideYUpTransition, SlideYDownTransition},
    data() {
        return {
            register: false,
            password: null,
            password_confirmed: null
        }
    },
    computed: {
        email() {
            return this.user.email
        }
    },
    created() {
        // console.info('OrderUser created',this.loggedEmail,this.user);
    },
    methods: {
        validate() {
            let isValid = this.$validator.validateAll()
            // this.$emit('on-validate', this.$data, isValid)
            if (!this.register) {
                this.password = null
                this.password_confirmed = null
            }
            return isValid
        }
    },
    watch: {
        // wallet(value){ this.$root.$emit('update:tx_id',value) }
    }
}
</script>
