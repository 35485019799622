<template>
    <div id="contact-form" class="card flipInX _animate card-panel shadow-lg">
        <div class="card-header">
            <h2 class="card-title"><i class="fas fa-envelope"></i> {{ trans('common.contact-form') }}</h2></div>

        <form>
            <div class="card-body">

                <vue-recaptcha
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    size="invisible"
                    :sitekey="recaptchaSite"
                >
                </vue-recaptcha>

                <div v-if="completed" id="completed">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <lottie v-if="completed"
                                    :options="animOptions"
                                    :height="200"
                            />
                        </div>
                        <div class="col-md-8 col-lg-9">
                            <!--<h3>{{ trans('common.contact-form-submitted') }}</h3>-->
                            <!--<div class="alert alert-success animated fadeInDown" v-html="trans('common.contact-form-submitted')"></div>-->
                            <p v-html="trans('common.contact-form-submitted')"></p>
                            <!--<p>{{ trans('common.you-can-close-this-page')}}</p>-->
                            <a :href="homepageLink" class="btn btn-primary">{{ trans('common.back-homepage') }}</a>
                        </div>

                    </div>
                </div>

                <div v-if="!completed">
                    <div class="form-group">
                        <label for="email">{{ trans('common.your-email') }}</label>
                        <input type="email" class="form-control" id="email" name="email"
                               v-model="email" v-validate="'required|email'"
                               :placeholder="trans('common.email-placeholder')"
                               v-bind:class="{ 'is-invalid': errors.has('email') }"
                        >
                        <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
                        <div class="invalid-feedback animated fadeIn" v-if="errors.has('email')">
                            {{ trans('common.validation-email') }}
                        </div>
                    </div>

                    <div v-if="order">
                        <label for="order">{{ trans('common.order') }}</label>
                        <div class="form-control mb-3 form-disabled" v-html="order.title" id="order"></div>
                    </div>

                    <div v-if="subject" class="form-group">
                        <label for="subject">{{ trans('common.subject') }}</label>
                        <input type="text" class="form-control" id="subject" name="subject"
                               v-model="subject"
                               v-validate="'required'"
                               :placeholder="trans('common.subject')"
                               v-bind:class="{ 'is-invalid': errors.has('subject') }">
                        <div class="invalid-feedback animated fadeIn" v-if="errors.has('subject') ">
                            {{ trans('common.validation-required') }}
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="text">{{ trans('common.contact-text-label') }}</label>
                        <textarea class="form-control" id="text" rows="3" name="text"
                                  v-model="text"
                                  v-validate="'required'"
                                  v-bind:class="{ 'is-invalid': errors.has('text') }"
                        ></textarea>
                        <div class="invalid-feedback animated fadeIn" v-if="errors.has('text')">
                            {{ trans('common.validation-required') }}
                        </div>
                    </div>

                </div>

                <zoom-y-transition :duration="500">
                    <div class="alert alert-danger" role="alert" v-if="errorMessage != false">
                        <i class="far fa-frown"></i> {{ errorMessage }}
                    </div>
                </zoom-y-transition>


                <!--<button type="button" @click="completed=!completed">complete</button>-->
            </div>

            <zoom-y-transition :duration="500">
                <div class="card-footer text-right" v-if="!completed">

                    <button-with-spinner type="button" btn-class="btn btn-primary"
                                         @click="submit"
                                         :label="trans('common.submit')"
                                         active-icon-class="fas fa-angle-right"
                                         :left-side="false"
                                         :loading="loading"/>

                </div>
            </zoom-y-transition>

        </form>
    </div>
</template>

<script>
import {ZoomYTransition} from 'vue2-transitions'
import VueRecaptcha from 'vue-recaptcha';
import Lottie from 'vue-lottie'
import animationSuccess from "../vendor/animation-email";
import ButtonWithSpinner from "./ButtonWithSpinner.vue";

export default {
    name: "contact-form",
    props: ['defaultEmail', 'order', 'contactFormUrl', 'recaptchaSite'],
    inject: ['trans'],
    data: () => {
        return {
            errorMessage: false,
            loading: false,
            isMounted: false,
            debug: false,
            completed: false,
            animOptions: {animationData: animationSuccess, loop: false},

            email: false,
            text: null,
            subject: false,
        }
    },
    components: {
        VueRecaptcha, ZoomYTransition, ButtonWithSpinner, Lottie
    },
    computed: {
        homepageLink() {
            return '/' + app.translator.locale + '/';
        }
    },
    created() {
        // console.info('ContactForm created ');
        let self = this;
        self.email = self.defaultEmail
    },
    mounted() {
        let self = this
        this.isMounted = true;
    },
    methods: {
        validate(next) {
            let self = this;
            self.loading = true
            this.$validator.validateAll().then(function (isValid) {
                if (isValid) {
                    next()
                } else {
                    self.loading = false;
                }
            }).catch(function (error) {
                self.loading = false
                self.errorMessage = error
            })
        },
        submit() {
            let self = this;
            // console.info('submit');
            this.validate(function () {
                self.loading = true
                self.$refs.recaptcha.execute();
                if (self.$refs.recaptcha.$el) {
                    self.$refs.recaptcha.$el.scrollIntoView(false)
                }
            })
        },
        onCaptchaVerified: function (recaptchaToken) {
            const self = this;
            self.$refs.recaptcha.reset();
            self.submitRequest(recaptchaToken)
        },
        onCaptchaExpired: function () {
            this.loading = false
            this.$refs.recaptcha.reset();
            this.$refs.recaptcha.execute();
        },
        submitRequest(recaptchaToken) {
            let self = this;
            self.loading = true //shared loader
            let data = {
                'g-recaptcha-response': recaptchaToken,
                'text': self.text,
                'email': self.email,
            }
            if (self.order) {
                data.order_id = self.order.id
            }
            if (self.subject) {
                data.subject = self.subject
            }

            laravel.ajax.send({
                method: 'POST',
                url: this.contactFormUrl,
                data: data,
                success: function (p) {
                    self.completed = true
                    self.loading = false;
                    app.analytics.trackGAEvent('contact-form', 'submit', (typeof data.order_id !== 'undefined') ? "orderId " + data.order_id : '')
                    self.$nextTick(() => {
                        let completeContainer = document.getElementById('completed');
                        if (completeContainer) {
                            completeContainer.scrollIntoView(true)
                        }
                    });

                },
                error: function (e) {
                    self.errorMessage = laravel.errors.singleErrorMessage(e)
                    app.analytics.trackGAEvent('contact-form', 'error', self.errorMessage)
                    self.loading = false
                }
            })
        },
    }
}
</script>

<style lang="scss">

#contact-form {

    border-radius: 17px;

    ._card-body {
        @media (max-width: 400px) {
            padding: 1.25rem 0;
        }
    }

    .form-disabled {
        background-color: #eee;
    }

    .grecaptcha-badge {
        display: none;
    }

}
</style>
