<template>
    <div id="order-calculator" class="order-calculator"  :class="{'order-calculator--submittable': allowSubmit}">

        <form @submit.prevent="onSubmit" @reset="onReset" novalidate>
            <div :class="{'row': !allowSubmit}">
                <div v-bind:class="colClass">
                    <div class="form-group">
                        <label for="from">{{ translator.trans('form.from-amount-label') }}</label>
                        <input v-model.number="from" id="from" class="from form-control amount"
                               type="number" :placeholder="translator.trans('form.from-amount-placeholder')"
                               @input="onFromChanged" autocomplete="off"
                        />
                        <v-select :value="fromCurrency" class="currency-select"
                                  :onChange="onFromCurrencyChanged"
                                  label="symbol"
                                  :options="currencies"
                                  :allowDeselect="false"
                                  :searchable="true"></v-select>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div v-bind:class="colClass">
                    <div class="form-group">
                        <label for="to">{{ translator.trans('form.to-amount-label') }}</label>
                        <input v-model.number="to" id="to" class="to form-control amount"
                               type="number" placeholder=""
                               @input="onToChanged" autocomplete="off"
                        />
                        <v-select :value="toCurrency"
                                  class="currency-select"
                                  :onChange="onToCurrencyChanged"
                                  label="symbol"
                                  :options="currencies"
                                  :allowDeselect="false"
                                  :searchable="true"></v-select>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div v-bind:class="colClass" v-if="allowSubmit">
                    <div class="form-group">
                        <button-with-spinner active-icon-class="fas fa-exchange-alt"
                                             @click="onSubmit"
                                             :loading="loading"
                                             :label="translator.trans('form.go-to-order')"/>
                    </div>
                </div>
            </div>

            <slide-y-up-transition>
                <div v-if="showError" class="alert alert-danger text-center mx-auto rounded-0 mt-3"
                     role="alert">
                    <i class="far fa-frown"></i> {{ errorMessage }}
                </div>
            </slide-y-up-transition>
        </form>

        <div v-if="debug">
            <div class="">orderType : {{ orderType == 1 ? 'buy' : 'sell' }}</div>
            <div class="">priority : {{ priority == 1 ? 'amount' : 'price' }}</div>
        </div>

    </div>
</template>

<script>
import debounce from 'debounce'
import parser from '../app/parser'
import vSelect from '../vendor/vue-select/Select.vue'
import translator from '../app/translator'
import ButtonWithSpinner from "./ButtonWithSpinner.vue";
import {SlideYUpTransition} from 'vue2-transitions'

const PRIORITY_AMOUNT = 1;
const PRIORITY_PRICE = 2;
const TYPE_BUY = 1;
const TYPE_SELL = 2;

export default {
    name: 'order-calculator',
    props: ['currencies', 'defaultFromCurrency', 'defaultToCurrency', 'defaultFrom',
        'orderPriceUrl', 'createOrderUrl', 'useSession'],
    data: () => {
        return {
            from: null,
            to: null,
            fromCurrency: null,
            toCurrency: null,
            fromCurrencies: [],
            toCurrencies: [],
            priority: PRIORITY_AMOUNT,
            debug: false,
            errorMessage: false,
            displayErrors: false,
            loading: false,
            translator: translator,
            checkAvailability: false,
            checkMinTrade: false,
            shouldContinueToForm: false,
        }
    },
    components: {
        SlideYUpTransition, vSelect, ButtonWithSpinner,
    },
    created: function () {
        let widget = this;
        // console.info('OrderCalculator created');
        app.header.onCurrencyChangedListeners.push(function (currencyObject) {
            let currency = widget.findCurrency(currencyObject.id)
            widget.onToCurrencyChanged(currency)
        })
        this.from = sessionStorage.getItem('orderFrom') || this.defaultFrom
        this.to = sessionStorage.getItem('orderTo')
        if (this.useSession) {
            this.fromCurrency = this.findCurrency(sessionStorage.getItem('orderFromCurrency') || this.defaultFromCurrency)
            this.toCurrency = this.findCurrency(sessionStorage.getItem('orderToCurrency') || this.defaultToCurrency)
        } else {
            this.fromCurrency = this.findCurrency(this.defaultFromCurrency)
            this.toCurrency = this.findCurrency(this.defaultToCurrency)
        }
        this.priority = sessionStorage.getItem('orderPriority') || PRIORITY_AMOUNT
        // this.$set(this, 'fromCurrencies', this.getFromCurrencies(this.toCurrency))
        // this.$set(this, 'toCurrencies', this.getToCurrencies(this.fromCurrency))

    },
    computed: {
        showError: function () {
            return this.displayErrors && this.errorMessage !== false
        },
        invertedPriority: function () {
            return this.priority == PRIORITY_AMOUNT ? PRIORITY_PRICE : PRIORITY_AMOUNT
        },
        loaded: function () {
            return !this.loading;
        },
        allowSubmit: function () {
            return this.createOrderUrl;
        },
        colClass: function () {
            if (this.allowSubmit) {
                return ['col-lg-12 col-sm-12']
            }
            return ['col-lg-4', 'col-sm-12']

        },
        orderType: function () {
            return (null !== this.toCurrency && this.toCurrency.isCrypto) ? TYPE_BUY : TYPE_SELL
        },
        isBuy: function () {
            return this.orderType == TYPE_BUY
        },
        isSell: function () {
            return this.orderType == TYPE_SELL
        }
    },
    watch: {
        orderType: function (fresh, old) {
            this.$root.$emit('update:orderType', fresh)
        },
        fromCurrency: function (fresh, old) {
            this.$root.$emit('update:fromCurrency', fresh)
        },
        toCurrency: function (fresh, old) {
            this.$root.$emit('update:toCurrency', fresh)
        },
        from: function (fresh, old) {
            this.$root.$emit('update:from', fresh)
        },
        to: function (fresh, old) {
            this.$root.$emit('update:to', fresh)
        },
        priority: function (fresh, old) {
            this.$root.$emit('update:priority', fresh)
        },

    },
    methods: {
        onReset: function () {
            // console.info('reset');
            this.isLoading(true)
        },
        validate() {
            this.displayErrors = false;
            this.checkAvailability = true
            this.checkMinTrade = true
            let self = this;
            // console.info('OrderCalculation Validate');
            return new Promise(function (resolve, reject) {
                self.doRequest(resolve, reject)
            })
        },
        calculatePrices: debounce(function () {
            // console.info('calculatePrices');
            if (parser.parseFloat(this.to) == 0 && parser.parseFloat(this.from) == 0) {
                return this.isLoading(false)
            }
            this.displayErrors = false;
            this.checkAvailability = false
            this.checkMinTrade = false
            this.doRequest()
        }, 500),
        onSubmit: debounce(function (e) {
            // console.info('submit');
            this.displayErrors = true;
            this.checkAvailability = true
            this.checkMinTrade = true
            this.shouldContinueToForm = true
            this.doRequest()
            app.analytics.trackGAEvent('order-calculator', 'submit', 'continue to order')
            return app.preventDefault(e)
        }, 1000, true),
        doRequest: function (resolve, reject) {
            // console.info('doRequest');
            this.isLoading(true)
            this.errorMessage = false;
            let self = this
            laravel.ajax.send({
                url: self.priceUrl(),
                success(p) {
                    // return console.info('doRequest success', p);
                    if (typeof resolve === 'function') {
                        resolve(true)
                    }
                    self.handleProcessData(p)
                    if (self.shouldContinueToForm) {
                        self.handleSaveData(p)
                        location.href = self.createOrderUrl
                    } else {
                        let gaLabel = ''
                        if (self.isBuy) {
                            gaLabel = 'buy ' + self.to + " " + self.toCurrency.symbol;
                        } else {
                            gaLabel = 'sell ' + self.from + " " + self.fromCurrency.symbol;
                        }
                        app.analytics.trackGAEvent('order-calculator', 'calculated', gaLabel)
                        self.isLoading(false)
                    }
                },
                error(e) {
                    // return console.info('doRequest error', e);
                    self.isLoading(false)
                    self.shouldContinueToForm = false
                    let error = laravel.errors.singleErrorMessage(e)
                    if (self.displayErrors) {
                        self.errorMessage = error;
                    }
                    app.analytics.trackGAEvent('order-calculator', 'error', error)
                    if (typeof reject === 'function') {
                        reject(error)
                    }
                }
            });
        },
        handleSaveData(p) {
            // console.info(p);
            sessionStorage.setItem('orderFrom', this.from)
            sessionStorage.setItem('orderTo', this.to)
            sessionStorage.setItem('orderFromCurrency', this.fromCurrency.id)
            sessionStorage.setItem('orderToCurrency', this.toCurrency.id)
            sessionStorage.setItem('orderPriority', this.priority)
        },
        handleProcessData(p) {
            if (p.price_priority === PRIORITY_AMOUNT) {
                if (p.type === TYPE_BUY && p.price > 0) {
                    this.from = parser.parseFloat(p.price)
                } else if (p.type === TYPE_SELL && p.price > 0) {
                    this.to = parser.parseFloat(p.price)
                }
            } else if (p.price_priority === PRIORITY_PRICE) {
                if (p.type === TYPE_BUY && p.amount > 0) {
                    this.to = parser.parseFloat(p.amount)
                } else if (p.type === TYPE_SELL && p.amount > 0) {
                    this.from = parser.parseFloat(p.amount)
                }
            }
        },
        onFromChanged: function (e) {
            // console.info('onFromChanged',e);
            this.isLoading(true)
            this.priority = this.isBuy ? PRIORITY_PRICE : PRIORITY_AMOUNT
            this.calculatePrices()
        },
        onToChanged: function (e) {
            // console.info('onToChanged',e);
            this.isLoading(true)
            this.priority = this.isSell ? PRIORITY_PRICE : PRIORITY_AMOUNT
            this.calculatePrices()
        },
        onFromCurrencyChanged: function (currency) {
            if (currency === this.fromCurrency) {
                return
            }
            //console.info('onFromCurrencyChanged',currency.id,'=>',this.fromCurrency.id);
            // console.info(['onFromCurrencyChanged',currency.id,this.getToCurrencies(currency).map( c => c.id ).join(' ') ]);
            // this.$set(this, 'toCurrencies', this.getToCurrencies(currency))
            if (currency.isCrypto === this.toCurrency.isCrypto) {
                this.toCurrency = this.fromCurrency
                this.priority = this.invertedPriority
            }
            this.fromCurrency = currency
            this.calculatePrices()
        },
        onToCurrencyChanged: function (currency) {
            if (currency === this.toCurrency) {
                return
            }
            //console.info('OrderCalculator.onToCurrencyChanged',currency.id,'=>',this.toCurrency.id);
            //console.info(['OrderCalculator.onToCurrencyChanged',currency.id,this.getFromCurrencies(currency).map( c => c.id ).join(' ') ]);
            //this.$set(this, 'fromCurrencies', this.getFromCurrencies(currency))
            // console.info({
            //   currency,
            //   currencyIsCrypto: currency.isCrypto,
            //   fromCurrency: this.fromCurrency,
            //   fromCurrencyIsCrypto: this.fromCurrency.isCrypto
            // });
            if (currency.isCrypto === this.fromCurrency.isCrypto) {
                this.fromCurrency = this.toCurrency
                if (this.priority === PRIORITY_PRICE) {
                    let to = this.to
                    this.to = this.from
                    this.from = to
                } else {
                    this.priority = this.invertedPriority
                }

            }
            this.toCurrency = currency
            app.header.activateCurrency(currency.id)
            this.calculatePrices()
        },
        swapCurrencies: function () {
            let lastFrom = this.fromCurrency;
            this.fromCurrency = this.toCurrency
            this.toCurrency = lastFrom;
            this.priority = this.invertedPriority()
        },
        clearTo: function () {
            this.to = null
        },
        clearFrom: function () {
            this.from = null
        },
        priceUrl: function (data = {}) {
            data.type = this.orderType
            data.price_priority = this.priority
            if (data.type === TYPE_BUY) {
                data.amount = parser.parseFloat(this.to)
                data.price = parser.parseFloat(this.from)
                data.market_currency_id = this.toCurrency.id
                data.base_currency_id = this.fromCurrency.id
            } else {
                data.amount = parser.parseFloat(this.from)
                data.price = parser.parseFloat(this.to)
                data.market_currency_id = this.fromCurrency.id
                data.base_currency_id = this.toCurrency.id
            }
            data.check_availability = this.checkAvailability ? 1 : 0
            data.check_min_trade = this.checkMinTrade ? 1 : 0

            // console.info('OrderCalculator.priceUrl', data);
            function encodeQueryData(data) {
                let ret = [];
                for (let d in data)
                    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
                return ret.join('&');
            }

            let querystring = encodeQueryData(data);
            return this.orderPriceUrl + "?" + querystring
        },
        isLoading: debounce(function (loading) {
            this.loading = loading
        }, 10),
        getFromCurrencies: function (current) {
            let currencies = [];
            for (let currency of this.currencies) {
                if (currency !== current) {
                    currencies.push(currency)
                } else {

                }
            }
            return currencies
        },
        getToCurrencies: function (current) {
            let currencies = [];
            for (let currency of this.currencies) {
                if (currency !== current) {
                    currencies.push(currency)
                }
            }
            return currencies
        },
        findCurrency: function (id) {
            return this.currencies.find(currency => currency.id === id);
        },
        clearStorage() {
            sessionStorage.removeItem('orderFrom');
            sessionStorage.removeItem('orderTo');
            sessionStorage.removeItem('orderFromCurrency');
            sessionStorage.removeItem('orderToCurrency');
            sessionStorage.removeItem('orderPriority');
        }


    }
}
</script>

<style lang="scss">
$amountW: 150px;
$amountCurrencyW: 85px;

.order-calculator {
    padding: 2rem 3rem;
    border-radius: 2px;
    color: #fff;
    max-width: 1000px;
    margin: 0 auto 0 0;

    &--submittable {
        max-width: 500px;
        background-color: rgba(125, 125, 125, 0.3);
    }

    .loading-container {
        padding-top: 32px;
        font-size: 30px;
    }

    label {
        width: 100%;
    }

    .v-select {
        width: $amountCurrencyW;
        float: left;

        .selected-tag {
            line-height: 1.5;
            font-size: 1rem;
            margin: 0.375rem 0.5rem;
        }

        input {
            height: auto;
            line-height: 1.5;
            font-size: 1rem;
            margin: 0.375rem 0;
        }

        .dropdown-toggle {
            background-color: #FFF;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .dropdown-menu {
            min-width: $amountCurrencyW !important;
        }
    }

    .btn-primary {
        width: 100%;
        margin-top: 8px;
        @media (min-width: 992px) {
            margin-top: 12px;
        }
    }

    .form-group {
        margin: 0 auto 1rem auto;
        width: $amountW + $amountCurrencyW;
    }

    .amount {
        width: $amountW;
        float: left;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media (min-width: 340px) {
        $amountW: 200px;
        .form-group {
            width: $amountW + $amountCurrencyW
        }

        .amount {
            width: $amountW;
            float: left;
        }
    }

    @media (min-width: 420px) and (max-width: 990px) {
        $amountW: 220px;
        .form-group {
            width: $amountW + $amountCurrencyW
        }

        .amount {
            width: $amountW;
            float: left;
        }
    }

}
</style>
