<template>
    <form id="order-summary" class="order-form-content">
        <div class="row">
            <div class="col-md-4 col-lg-3">
                <lottie v-if="active"
                        :options="animOptions"
                        :height="200"
                        style="margin-top: -30px"
                />
            </div>
            <div class="col-md-8 col-lg-9">
                <p v-html="trans('order.summary-description')"></p>
                <ul>
                    <li>{{ trans('common.summary-i-want-exchange-from') }} <strong>{{ finalModel.from }}
                        {{ finalModel.fromCurrency.symbol }}</strong></li>
                    <li>{{ trans('common.summary-i-want-exchange-to') }} <strong>{{ finalModel.to }}
                        {{ finalModel.toCurrency.symbol }}</strong></li>
                    <li>{{
                            isCrypto ? trans('common.summary-transfer-wallet') : trans('common.summary-transfer-account')
                        }}
                        <br><code style="word-wrap: break-word">{{ finalModel.wallet }}</code></li>
                    <li>{{ trans('common.summary-my-email') }} <strong>{{ finalModel.email }}</strong></li>
                </ul>
                <div class="tab-content">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="1" id="acceptTerms" name="acceptTerms"
                               v-bind:class="{ 'is-invalid': errors.has('acceptTerms') }"
                               v-model="acceptTerms"
                               v-validate="'required'">
                        <label class="form-check-label" for="acceptTerms">
                            {{ trans('common.i-agree-with-terms-before') }} <a v-bind:href="termsLink" target="_blank">{{
                                trans('common.i-agree-with-terms')
                            }} </a> {{ trans('common.i-agree-with-terms-after') }}
                        </label>
                        <div class="invalid-feedback animated fadeIn" v-if="errors.has('acceptTerms')">
                            {{ trans('common.validation-terms-and-conditions') }}
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="1" id="acceptPrivacy"
                               name="acceptPrivacy"
                               v-model="acceptPrivacy"
                               v-bind:class="{ 'is-invalid': errors.has('acceptPrivacy') }"
                               v-validate="'required'">
                        <label class="form-check-label" for="acceptPrivacy">
                            {{ trans('common.i-agree-with-privacy-before') }} <a v-bind:href="policyLink"
                                                                                 target="_blank">{{
                                trans('common.i-agree-with-privacy')
                            }} </a> {{ trans('common.i-agree-with-privacy-after') }}
                        </label>
                        <div class="invalid-feedback animated fadeIn" v-if="errors.has('acceptPrivacy')">
                            {{ trans('common.validation-privacy-policy') }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>
</template>


<script>
import Lottie from 'vue-lottie'
// import animationComplete from "../vendor/animation_complete";
// import animationSuccess from "../vendor/animation-success";
// import animationCheck from "../vendor/animation-check";
// import animationTick from "../vendor/animation-tick";
import animationTodos from "../vendor/animation_todos";

export default {
    name: 'order-summary',
    props: ['finalModel', 'active', 'isCrypto'],
    inject: ['trans'],
    components: {Lottie},
    data() {
        return {
            acceptTerms: false,
            acceptPrivacy: false,
            animOptions: {animationData: animationTodos, loop: false},
        }
    },
    created() {
    },
    mounted() {
        this.$root.$on('wizard-step-summary', active => {
            if (active) {
                document.getElementById('order-form').scrollIntoView(true);
            }
        })
    },
    computed: {
        policyLink() {
            return '/' + app.translator.locale + '/privacy-policy'
        },
        termsLink() {
            return '/' + app.translator.locale + '/terms-and-conditions'
        }
    },
    methods: {
        validate() {
            let isValid = this.$validator.validateAll()
            this.$emit('on-validate', this.$data, isValid)
            return isValid
        }
    }
}
</script>

<style lang="scss">
#order-summary {
    label a {
        color: black;
        text-decoration: underline;
    }

    .is-invalid + label a {
        color: #dc3545;
    }
}
</style>
