export default class Currency {

    /**
     *
     * @param {string} id
     * @param {string} symbol
     * @param {string} name
     * @param {boolean} isCrypto
     * @param {HTMLElement|null} elHeader
     */
    constructor(id, symbol, isCrypto, name, elHeader) {
        this.id = id
        this.symbol = symbol
        this.name = name
        this.isCrypto = isCrypto
        this._isActive = false
        this.elHeader = elHeader || null

        if (this.elHeader && this.elHeader.classList.contains('active')) {
            this._isActive = true
        }
        // console.info('Currency created', this);
    }

    set isActive(value) {
        this._isActive = value
        if (this.elHeader) {
            if (value) {
                this.elHeader.classList.add('active')
            } else {
                this.elHeader.classList.remove('active')
            }
        }
    }

    get isActive() {
        return this._isActive
    }

}



