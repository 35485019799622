<template>
    <div>
        <div v-if="type.isText" class="form-group">
            <label :for="name" v-if="showLabel">{{ type.title }}</label>
            <input type="text"
                   class="form-control"
                   :id="name"
                   :name="name"
                   v-model="value"
                   :placeholder="type.title"
                   @input="valueUpdated($event.target.value)"
                   v-bind:class="{ 'is-invalid': error }"
            >
            <div class="error-message animated fadeIn" v-if="error">{{ errorMessage }}</div>
        </div>

        <div v-if="type.isFile" class="form-row file-group">
            <div class="col-sm-4 col-md-3" v-if="imagePath">
                <img v-bind:src="imagePath" class="example" v-bind:alt="type.title"/>
            </div>
            <div class="col-sm-8 col-md-9">
                <vue-dropzone ref="dropzone" :id="name"
                              :options="dropzoneOptions"
                              @vdropzone-success="fileUploaded"
                              @vdropzone-sending="fileSending"
                              @vdropzone-error="fileError"
                              @vdropzone-removed-file="fileRemoved"
                              v-bind:class="{ 'is-invalid': error }"
                />
                <div class="error-message animated fadeIn" v-if="error">{{ errorMessage }}</div>
            </div>
        </div>


        <div v-if="type.isDate" class="form-group">
            <label :for="name" v-if="showLabel">{{ type.title }}</label>
            <datepicker v-model="value" :name="name"
                        :placeholder="type.title"
                        :input-class="{ 'form-control':true, 'is-invalid': error }"
                        :bootstrap-styling="true"
                        :language="datepickerLanguage"
                        autocomplete="off"
                        @input="valueUpdated"
                        format="dd.MM.yyyy"
                        initial-view="year"
                        :typeable="false"
                        calendar-button-icon="fa fa-calendar"
            />
            <div class="error-message animated fadeIn" v-if="error">{{ errorMessage }}</div>
        </div>

        <div v-if="type.isSelect" class="form-group">
            <label :for="name" v-if="showLabel">{{ type.title }}</label>
            <v-select class=""
                      :name="name"
                      :value="vueSelectValue"
                      :onChange="valueUpdated"
                      :options="selectOptions"
                      :allowDeselect="false"
                      :placeholder="type.title"
                      :searchable="true"
                      v-bind:class="{ 'is-invalid': error }"
            >
            </v-select>
            <div class="error-message animated fadeIn" v-if="error">{{ errorMessage }}</div>
        </div>

        <div v-if="debug" style="margin-top: -10px; margin-bottom: 20px;">
            value: <strong>{{ value }}</strong>
        </div>
    </div>
</template>


<script>
import vSelect from '../vendor/vue-select/Select.vue'
import Datepicker from 'vuejs-datepicker';
import {cs, en} from 'vuejs-datepicker/dist/locale'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: 'kyc-field',
    props: {
        name: String,
        type: {
            type: Object,
            required: true
        },
        defaultValue: {
            type: [String, Number],
            required: false,
            default: null
        },
        error: String,

    },
    inject: ['trans'],
    $_veeValidate: {
        // value getter
        value() {
            // console.info('kycField value:',this.value);
            return this.value
        },
        name() {
            // console.info('kycField name:',this.name);
            return this.name
        }
    },
    data() {
        return {
            value: null,
            debug: false,
            mounted: false,
            dropzoneOptions: {
                url: '/api/upload-kyc-file',
                thumbnailHeight: 150,
                // maxFilesize: 20,
                headers: app.util.requestHeaders(),
                dictDefaultMessage: this.uploadLabel(),
                dictRemoveFile: this.trans('common.remove-file'),
                resizeWidth: 1200,
                resizeHeight: 1200,
                uploadMultiple: false,
                maxFiles: 1,
                acceptedFiles: '.png,.jpg,.jpeg,.bmp',
                addRemoveLinks: true
            },
            vueSelectValue: null,
        }
    },
    computed: {
        selectOptions() {
            if (this.type.isSelect) {
                let options = []
                for (let obj in this.type.selectOptions) {
                    options.push({value: obj, label: this.type.selectOptions[obj]})
                }
                // console.info(this.type.title,options);
                return options;
            }
            return [];
        },
        showLabel() {
            return this.type.isFile
        },
        imagePath() {
            if (this.name == 'IDENTITY_FRONT') {
                return '/front/id_front.png'
            } else if (this.name == 'IDENTITY_BACK') {
                return '/front/id_back.png'
            } else if (this.name == 'IDENTITY_SELFIE') {
                return '/front/id_selfie.png'
            }
            return false
        },
        locale() {
            return app.translator.locale
        },
        datepickerLanguage() {
            if (this.locale == 'cs') {
                return cs;
            }
            return en;
        },
        errorMessage() {
            if (this.type.validationMessage) {
                return this.type.validationMessage;
            }
            if (this.locale == 'cs') {
                return `Prosím vyplnťe pole ${this.type.title}.`
            }
            return `The ${this.type.title} is required`
        }
    },
    created() {
        if (this.defaultValue) {
            if (this.type.isSelect) {
                this.vueSelectValue = {value: this.defaultValue, label: this.type.selectOptions[this.defaultValue]}
            } else if (this.type.isDate) {
                const datePickerDate = new Date(this.defaultValue * 1000)
                this.valueUpdated(datePickerDate)
            } else {
                this.value = this.defaultValue
            }
        }
    },
    mounted() {
        let self = this;
        this.mounted = true
        // this.$root.$on('kycField:update', this.someFieldChanged )
        if (this.type.name === 'STATE') {
            this.$root.$on('kycField:update', function (type, value) {
                if (type.name === 'COUNTRY') {
                    self.type.isSelect = value === 'US'
                    self.value = null
                }
            })
        }
        if (this.type.isFile && this.value) {
            this.$refs.dropzone.manuallyAddFile({size: 123, name: this.type.name, type: "image/jpg"}, this.value);
            setTimeout(() => {
                this.fileUploaded({})
            }, 100)
        }
    },
    methods: {
        uploadLabel() {
            return "<i class='fas fa-cloud-upload-alt'></i> "
                + "<strong>" + this.type.title + "</strong>"
                + "<br>"
                + "<small>" + this.trans('common.drop-file') + "</small>";
        },
        fileUploaded(response) {
            this.value = 'uploaded'
            this.valueUpdated(this.value)
            this.$refs.dropzone.disable()
        },
        fileRemoved(file, error, xhr) {
            this.value = null
            this.valueUpdated(this.value)
            this.$refs.dropzone.enable()
        },
        fileSending(file, xhr, formData) {
            formData.append('type', this.type.id);
        },
        fileError(file, message, xhr) {
            let error = '';
            if (xhr) {
                error = laravel.errors.singleErrorMessage(xhr)
                //console.info(xhr,error);

            }
            $(file.previewElement).find('.dz-error-message').html(error)
        },
        valueUpdated(value) {
            this.value = value;
            this.$emit('input', value)

            if (this.type.isDate && value instanceof Date) {
                // value = app.util.mysqlDate(value)
                value = value.getTime() / 1000 //UTC ts
            } else if (this.type.isSelect) {
                value = value.value;
            }
            this.$root.$emit('kycField:update', this.type, value)
        },
        someFieldChanged(type, value) {
            //console.info('someFieldChanged',type,value);
        }
    },
    components: {
        vSelect, Datepicker, vueDropzone
    },
}
</script>

<style scoped lang="scss">
$gocoin: #5E57EC;
$gocoin2: #9747DC;
$gray: #ebebeb;
$red: #dc3545 !default;
$blue: #007bff !default;

.file-group {
    padding-top: 0.5rem;
    /*border-top: 1px solid #ddd;*/
    .error-message {
        margin: 0 0 0.5rem 1rem;
    }

    .example {
        width: 100%;
    }

    @media (max-width: 575px) {
        margin-bottom: 2rem;

        .error-message {
            margin: 0;

        }
    }

}

.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.already-uploaded-img {
    margin: 0 0 0.25rem 1rem;

    img {
        max-height: 150px;
        max-width: 500px;
    }
}

.vue-dropzone {

    &.is-invalid {
        border: 1.0px solid $red;
    }

    border: 1.0px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    margin: 0 0 0.25rem 1rem;

    .dz-message {
        font-family: 'Arimo', sans-serif;
        margin: 1rem 0;
    }

    @media (max-width: 575px) {
        margin: 1rem 0 0.25rem 0;
        min-height: 128px;
    }

    .dz-preview {
        border: 1px solid #CCC;

        .dz-progress {
            background-color: $gocoin;
        }

        .dz-details {
            background-color: #f6f6f6;
            color: black;
            font-family: 'Arimo', sans-serif;
        }

        .dz-success-mark {
            color: $gocoin;
        }

        .dz-remove {
            font-family: 'Arimo', sans-serif;
            display: inline-block;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            user-select: none;
            text-decoration: none !important;
            text-transform: none;
            padding: 0.375rem 0.75rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            font-weight: 400;
            font-size: 1rem;
            /*background-color: #ffc107;*/
            border: 1px solid #dc3545;
            color: #fff;
            background-color: #dc3545;
        }
    }
}

.v-select.is-invalid .dropdown-toggle {
    border: 1.0px solid $red;
}

</style>
