import prevent from './preventDefault'

var cookies = {
    $bar: null,
    init: function () {
        this.$bar = $('.cookies-bar');
        this.$bar.on('click', '.cookies__agree', cookies.closeClicked);
    },
    closeClicked: function (e) {
        laravel.ajax.send({
            url: $(this).attr('href'),
            success: cookies.hideBar
        });
        return prevent(e);
    },
    hideBar: function (payload) {
        let $bar = cookies.$bar;
        $bar.css('transition-duration', '1s');
        $bar.css('bottom', -1.3 * $bar.height());
        setTimeout($bar.remove, 1000);
    }

};

export default cookies
