<template>
    <div class="account-form">
        <div class="account-form-header">
            <h3>{{ account.number }}</h3>
            <span v-if="approved" class="text-success animated fadeInRight"><i
                class="fas fa-clipboard-check"></i> {{ trans('common.approved') }}</span>
            <span v-else-if="denied" class="text-danger animated fadeInRight"><i
                class="fas fa-exclamation-triangle"></i> {{ trans('common.approval_status-denied') }}</span>
            <span v-else-if="approvalPending" class="text-warning animated fadeInRight"><i
                class="fas fa-check-circle"></i> {{ trans('common.wait_for_approval') }}</span>
            <span v-else class="text-danger animated fadeInRight"><i
                class="fas fa-clock"></i> {{ trans('common.wait_for_verification') }}</span>
        </div>
        <div class="file-group animated zoomIn animation-delay-1" v-show="account.verifiable">
            <input type="hidden" :value="verification" name="verification" v-validate="verificationRules">
            <vue-dropzone ref="dropzone" id="accountVerificationDz"
                          :options="dropzoneOptions"
                          @vdropzone-success="fileUploaded"
                          @vdropzone-sending="fileSending"
                          @vdropzone-error="fileError"
                          @vdropzone-removed-file="fileRemoved"
                          v-bind:class="{ 'is-invalid': errors.has('verification') }"
            />
            <p class="text-muted"><i class="fas fa-info-circle"></i> <span
                v-html="trans('common.account-verification-label')"></span></p>

            <!--{{ errors }}-->
            <!--{{ verificationRules }}-->
            <div class="error-message" v-if="errors.has('verification')">{{ errorMessage }}</div>
        </div>

    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

const APPROVED = 10
const DENIED = 5
const PENDING = 0

export default {
    name: "account-form",
    props: {
        account: {
            type: Object,
            required: true,
        },
        verificationRequired: {
            type: Boolean,
            required: true
        }
    },
    inject: ['trans'],
    components: {vueDropzone},
    computed: {
        locale() {
            return app.translator.locale
        },
        errorMessage() {
            if (this.locale == 'cs') {
                return `Prosím nahrajte ověření účtu.`
            }
            return `Please upload account verification`
        },
        verificationRules() {
            if (this.account.verifiable && this.verificationRequired) {
                return 'required|is:uploaded'
            }
            return ''
        },
        approvalPending() {
            return this.account && this.account.approval_status === PENDING
        },
        approved() {
            return this.account && this.account.approval_status === APPROVED
        },
        denied() {
            return this.account && this.account.approval_status === DENIED
        },
    },
    data() {
        return {
            verification: null,
            number: this.account.number,
            dropzoneOptions: {
                url: '/api/accounts/upload-verification',
                thumbnailHeight: 150,
                // maxFilesize: 20,
                headers: app.util.requestHeaders(),
                dictDefaultMessage: this.uploadLabel(),
                dictRemoveFile: this.trans('common.remove-file'),
                resizeWidth: 1200,
                resizeHeight: 1200,
                uploadMultiple: false,
                maxFiles: 1,
                acceptedFiles: '.png,.jpg,.jpeg,.bmp,.pdf',
                addRemoveLinks: true
            }
        }
    },
    methods: {
        validate() {
            let isValid = this.$validator.validateAll()
            this.$emit('on-validate', this.$data, isValid)
            return isValid
        },
        uploadLabel() {
            return "<i class='fas fa-cloud-upload-alt'></i> "
                + "<strong>" + this.trans('common.account-verification-upload') + "</strong>"
                + "<br>"
                + "<small>" + this.trans('common.drop-file') + "</small>";
        },
        fileUploaded(file, response) {
            const account = response.account
            this.verification = 'uploaded'
            this.$emit('account:update', account)
            this.$refs.dropzone.disable()
        },
        fileRemoved(file, error, xhr) {
            // it looks like this is not in use
            this.verification = null
            this.$emit('account:update', {
                verifiable: true,
                verified: false,
                approval_status: 0,
            })
            this.$refs.dropzone.enable()
        },
        fileSending(file, xhr, formData) {
            formData.append('number', this.number);
        },
        fileError(file, message, xhr) {
            let error = '';
            this.validate()
            if (xhr) {
                error = laravel.errors.singleErrorMessage(xhr)
                // console.info(xhr,error);

            }
            $(file.previewElement).find('.dz-error-message').html(error)
        }
    }

}
</script>

<style lang="scss">
$gocoin: #5E57EC;
$gocoin2: #9747DC;
$gray: #ebebeb;
$red: #dc3545 !default;
$blue: #007bff !default;
.account-form {
    /*border-radius: 0.75rem;*/
    /*border: 1px #eee solid;*/
    /*padding:1rem;*/

    margin: 10px -2.25rem;
    padding: 1rem 2rem;

    &-header {
        h3 {
            display: inline-block;
            font-weight: 700;
            font-size: 1rem;
        }

        span {
            margin-left: 1rem;
        }

        @media (max-width: 700px) {
            h3 {
                display: block;
            }
            span {
                display: block;
                margin: 0;
            }
        }
    }

    &:nth-child(odd) {
        background-color: #f6f6f6;
        border-bottom: 1px solid $gray;

    }

    &:nth-child(even) {
        .vue-dropzone {
        }
    }

    @media (max-width: 400px) {
        margin: 10px -1.5rem;

    }

    .error-message {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }

    .vue-dropzone {

        &.is-invalid {
            border: 1.0px solid $red;
        }

        border: 1.0px solid #ced4da;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem;
        margin: 0 0 0.5rem;

        .dz-message {
            font-family: 'Arimo', sans-serif;
            margin: 1rem 0;
        }

        @media (max-width: 575px) {
            margin: 1rem 0;
            min-height: 128px;
        }

        .dz-preview {
            border: 1px solid #CCC;

            .dz-progress {
                background-color: $gocoin;
            }

            .dz-details {
                background-color: #f6f6f6;
                color: black;
                font-family: 'Arimo', sans-serif;
            }

            .dz-success-mark {
                color: $gocoin;
            }

            .dz-remove {
                font-family: 'Arimo', sans-serif;
                display: inline-block;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                text-decoration: none !important;
                text-transform: none;
                padding: 0.375rem 0.75rem;
                line-height: 1.5;
                border-radius: 0.25rem;
                font-weight: 400;
                font-size: 1rem;
                //background-color: #ffc107;
                border: 1px solid #dc3545;
                color: #fff;
                background-color: #dc3545;
            }
        }
    }

}
</style>
