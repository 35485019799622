import "./../scss/app.scss"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'animate.css'

import '@babel/polyfill';
import jQuery from 'jquery'
import './vendor/laravel.ajax'
import './vendor/bootstrap'

import graphs from './app/graph'
import exchangeRates from './app/exchangeRates'
import navbar from './app/navbar'
import preventDefault from './app/preventDefault'
import fontawesome from './app/fontawesome'
import header from './app/header'
import cookies from './app/cookies'
import translator from './app/translator'
import auth from './app/auth'
import util from './app/util'
import analytics from './app/analytics'
// import bgEffect from './app/bgEffectNodes'
// import bgEffect from './app/bgEffectCurl'
import bgEffect from './app/bgEffectChain'
// import bgEffect from './app/bgEffectWaves'
import Vue from 'vue'
import Lottie from 'vue-lottie'
import animationSuccess from "./vendor/animation_complete";
import animationEmail from "./vendor/animation-email";
// import BootstrapVue from "bootstrap-vue"
// Vue.use(BootstrapVue)
import vueCookies from 'vue-cookies'
import VeeValidate from 'vee-validate';
import OrderCalculator from './components/OrderCalculator.vue'
import OrderConfirmation from './components/OrderConfirmation.vue'
import OrderForm from './components/OrderForm.vue'
import AccountsStep from './components/AccountsStep.vue'
import ProfileKyc from './components/ProfileKyc.vue'
import ContactForm from './components/ContactForm.vue'
import TestComm from './components/TestComm.vue'
import ProfileVeriff from './components/ProfileVeriff.vue'
import ProfileSumsub from "./components/ProfileSumsub.vue";
import CookiesSettings from "./components/CookiesSettings.vue";

global.jQuery = global.$ = jQuery;

Vue.use(VeeValidate);
Vue.use(vueCookies);

global.app = {
    navbar,
    header,
    graphs,
    exchangeRates,
    fontawesome,
    preventDefault,
    cookies,
    translator,
    bgEffect,
    util,
    analytics,

    init: function (options) {
        if (options.debug) {
            Vue.config.devtools = true;
            // it doesn't work because we are using minified version of vue.js
        }
        auth.email = options.email
        auth.phone = options.phone
        auth.setApiToken(options.apiToken)
        auth.id = options.authId || null
        this.translator.init(options.locale)
        this.navbar.init()
        this.fontawesome.init()
        this.graphs.init();
        this.bgEffect.init(options)
        this.analytics.init(options)
        this.initReferral()

        $('body').scrollspy({target: '.spy-active'});
    },

    initOrderCalculator: function (props) {
        new Vue({
            el: '#order-calculator',
            render: h => h(OrderCalculator, {
                props: props
            })
        })

    },
    initOrderForm: function (props) {
        new Vue({
            el: '#order-form',
            render: h => h(OrderForm, {
                props: props
            })
        })

    },
    initOrderConfirmation: function (props) {
        new Vue({
            el: '#order-confirmation',
            render: h => h(OrderConfirmation, {
                props: props
            })
        })

    },
    initProfileAccounts: function (props) {
        props.kycThreshold = props.user.kyc_threshold
        props.verificationRequired = props.user.need_account_verification
        new Vue({
            el: '#profile-accounts',
            provide() {
                return {
                    trans(term) {
                        return translator.trans(term)
                    }
                }
            }, render: h => h(AccountsStep, {
                props: props
            })
        })
    },
    initProfileKyc: function (props) {
        props.kycThreshold = props.user.kyc_threshold
        props.verificationRequired = props.user.need_account_verification
        new Vue({
            el: '#profile-kyc',
            provide() {
                return {
                    trans(term) {
                        return translator.trans(term)
                    }
                }
            }, render: h => h(ProfileKyc, {
                props: props
            })
        })
    },
    initProfileVeriff: function (props) {
        new Vue({
            el: '#profile-veriff',
            provide() {
                return {
                    trans(term) {
                        return translator.trans(term)
                    }
                }
            }, render: h => h(ProfileVeriff, {
                props: props
            })
        })
    },
    initProfileSumsub: function (props) {
        new Vue({
            el: '#profile-sumsub',
            provide() {
                return {
                    trans(term) {
                        return translator.trans(term)
                    }
                }
            }, render: h => h(ProfileSumsub, {
                props: props
            })
        })
    },
    initContactForm(props) {
        props.defaultEmail = auth.email
        new Vue({
            el: '#contact-form',
            provide() {
                return {
                    trans(term) {
                        return translator.trans(term)
                    }
                }
            }, render: h => h(ContactForm, {
                props: props
            })
        })
    },
    initSuccessAnimation: function (options = {}) {
        let animation = animationSuccess
        if (options.animation == 'animationEmail') {
            animation = animationEmail
        }
        new Vue({
            el: '#success-animation',
            components: {Lottie},
            template: ' <lottie  :options="options" :height="150" />',
            data() {
                return {options: {animationData: animation, loop: false}}
            }
        })
    },
    initTestComm(props) {
        new Vue({
            el: '#test-comm',
            render: h => h(TestComm, {
                props: props
            })
        })
    },
    initReferral: function () {
        // get referral link from url query string
        let referral = util.getUrlParameter('referral_code')
        if (referral) {
            sessionStorage.setItem('referralCode', referral)
        }
    },
    initLaravelShare: function () {
        let popupSize = {
            width: 780,
            height: 550
        };

        $(document).on('click', '.social-button', function (e) {
            let verticalPos = Math.floor(($(window).width() - popupSize.width) / 2),
                horisontalPos = Math.floor(($(window).height() - popupSize.height) / 2);

            let popup = window.open($(this).prop('href'), 'social',
                'width=' + popupSize.width + ',height=' + popupSize.height +
                ',left=' + verticalPos + ',top=' + horisontalPos +
                ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

            if (popup) {
                popup.focus();
                e.preventDefault();
            }

        });
    },
    initCookiesSettings: function (props) {
        new Vue({
            el: '#cookies-settings',
            render: h => h(CookiesSettings, {
                props: props
            })
        })
    }
}
