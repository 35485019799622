export default {
    parseFloat: function (obj) {
        if (isNaN(obj) || obj == null || obj === undefined) {
            return 0.0;
        }
        if (typeof obj === 'string' || obj instanceof String) {
            if (obj == '') {
                return 0.0
            }
            obj = obj.replace(',', '.')
            obj = obj.replace(' ', '')
            obj = obj.replace('\'', '')
        }
        return parseFloat(obj)
    }
}
