<template>
    <div class="card">
        <h1>Child test comm</h1>
        <div class="mb-2">
            initProp:
            <input type="text" v-model="initProp">
            <strong>{{ initProp }}</strong>
        </div>
        <div class="mb-2">
            var:
            <input type="text" v-model="variable">
            <strong> {{ variable }}</strong>
        </div>
        <div class="mb-2">
            prop:
            <input type="text" v-model="property">
            <strong>{{ property }}</strong>
        </div>
        <div class="mb-2">
            synced:
            <input type="text" :value="synced"
                   @input="$emit('update:synced',$event.target.value)"/>
            <strong>{{ synced }}</strong>
        </div>
        <div class="mb-2">
            translation: <strong>{{ trans('common.terms-and-conditions') }}</strong>
        </div>
        <button @click="emitTestEvent">Emit testEvent</button>

    </div>
</template>

<script>
export default {
    name: 'test-comm-child',
    props: ['initProp', 'initVariable', 'property', 'synced'],
    inject: ['trans'],
    data() {
        return {
            variable: this.initVariable
        }
    },
    methods: {
        onSynced: function (val) {
            console.info('child on update:synced', val)
        },
        emitTestEvent() {
            this.$emit('test-event', this.$data)
        }
    },
    created() {
        let self = this
        console.info('child created');
        this.$on('update:synced', function (val) {
            self.onSynced(val)
        })
    }

}
</script>
