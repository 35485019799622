export default {
    email: null,
    phone: null,
    id: null,
    apiToken: null,
    setApiToken(token) {
        this.apiToken = token;
        const options = {headers: this.getHeaders()};
        // console.info('ajax setup', options);
        $.ajaxSetup(options);
    },
    getBearer() {
        return 'Bearer ' + this.apiToken;
    },
    getHeaders() {
        return {
            'X-CSRF-TOKEN': this.getCsrfToken(),
            'Authorization': this.getBearer(),
            'Accept': 'application/json'
        }
    },
    getCsrfToken() {
        return $('meta[name=csrf_token]').attr('content') || $('meta[name=_token]').attr('content');
    }
}
