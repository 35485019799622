// Prevent Event propagation
// @author dan@macaque.cz
//
//  ------  Usage  -----------
// $('a').click(function (event) {
//     return preventDefault(event);
// });

export default function (event) {
    event.preventDefault();
    event.stopPropagation();
    event.returnValue = false;
    return false;
};
