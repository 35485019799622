<template>
    <div id="profile-kyc">

        <kyc-form ref="kycForm"
                  :verification-required="verificationRequired"
                  :kyc-threshold="kycThreshold"
                  :type-groups="typeGroups"
                  :init-data="user.kyc_data"
                  v-if="!complete"
        />

        <zoom-y-transition :duration="500">
            <div class="alert alert-danger" role="alert" v-if="errorMessage != false">
                <i class="far fa-frown"></i> {{ errorMessage }}
            </div>
        </zoom-y-transition>

        <button-with-spinner
            type="button" btn-class="btn btn-primary pull-right"
            @click="submit"
            :label="trans('common.save')"
            active-icon-class="far fa-save"
            :left-side="false"
            :loading="loading"
            v-if="!complete"
        />

        <div v-if="complete" id="complete">
            <lottie v-if="complete"
                    :options="animOptions"
                    :height="200"
            />
            <p>{{ trans('common.kyc-submitted') }}</p>
        </div>


    </div>
</template>

<script>
import KycForm from './KycForm.vue'
import ButtonWithSpinner from './ButtonWithSpinner.vue'
import {ZoomYTransition} from 'vue2-transitions'
import Lottie from 'vue-lottie'
import animationSuccess from "../vendor/animation_todos";

export default {
    name: "profile-kyc",
    components: {KycForm, ButtonWithSpinner, ZoomYTransition, Lottie},
    props: ['kycThreshold', 'verificationRequired', 'user', 'typeGroups'],
    inject: ['trans'],
    data() {
        return {
            loading: false,
            errorMessage: false,
            complete: false,
            animOptions: {animationData: animationSuccess, loop: false},
        }
    },
    methods: {
        submit() {
            let self = this;
            self.loading = true;
            self.errorMessage = false;
            self.$refs.kycForm.validate().then(function (isValid) {
                self.loading = false;
                if (!isValid) {
                    self.errorMessage = isValid
                } else {
                    self.complete = true
                    $('.kyc-status-hide').remove();
                    $('#kyc-status').slideUp();
                    self.$nextTick(function () {
                        document.getElementById('scroll-target').scrollIntoView(true)
                    })

                }
            }).catch(function (error) {
                self.errorMessage = error;
                self.loading = false;
            })


        },
        doRequest() {
            laravel.ajax.send({
                url: '/api/'
            })
        }

    }
}
</script>

<style scoped>

</style>
