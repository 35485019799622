<template>
    <form id="order-email" class="order-form-content">
        <div class="form-group">
            <label for="email">{{ trans('order.email-label') }}</label>
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend"><i
                    class="fas fa-envelope"></i></span></div>
                <input name="email" type="email" id="email" class="form-control"
                       v-model="email"
                       v-validate="'required|email'"
                       :class="{ 'is-invalid': errors.has('email')  }"
                       :placeholder="trans('common.email-placeholder')">
                <div class="invalid-feedback animated fadeIn" v-if="errors.has('email')">
                    {{ trans('common.validation-email') }}
                </div>
                <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
            </div>
        </div>

        <div class="order-customer__rejected" v-if="customerRejected">
            <p>{{ trans('order.kyc-customer-rejected') }}</p>
            <p v-if="customerRejected.user_email">{{ trans('common.customer') }} email: <em>{{ customerRejected.user_email }}</em></p>
        </div>

        <div class="form-group">
            <label for="email">{{ trans('order.contact-phone') }} - <em>{{ trans('order.contact-phone-explain') }}</em></label>
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend"><i
                    class="fas fa-phone"></i></span></div>
                <input name="phone" type="text" id="phone" class="form-control"
                       v-model="phone"
                       v-validate="{required:true, regex: /^\+[0-9 ]{10,16}$/}"
                       :class="{ 'is-invalid': errors.has('phone')  }"
                       :placeholder="trans('common.phone-placeholder')">
                <div class="invalid-feedback animated fadeIn" v-if="errors.has('phone')">
                    {{ trans('common.validation-phone') }}
                </div>
                <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
            </div>
        </div>

        <div class="form-group" v-if="countries">
            <label for="country">{{ trans('common.kyc-country-residence-select') }}</label>
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend"><i
                    class="fas fa-globe"></i></span></div>
                <v-select v-model="country" class="country-select"
                          :onChange="onCountryChange"
                          :options="countries"
                          :allowDeselect="false"
                          :class="{ 'is-invalid': errors.has('countryCode')  }"
                          :searchable="true"/>
                <input type="hidden" name="countryCode" v-model="countryCode" v-validate="'required|allowedCountry'"/>
                <div class="country-validation-error animated fadeIn" v-if="errors.has('countryCode')">
                    {{ trans("common.kyc-allowed-country-validator") }}
                </div>
            </div>
        </div>

        <div class="form-group" v-if="false && applicantTypes">
            <label for="applicantType">{{ trans('common.sumsub-applicant-type') }}</label>
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend"><i
                    class="fas fa-industry"></i></span></div>
                <v-select v-model="applicantType" class="applicant_type-select"
                          :onChange="onApplicantTypeChange"
                          :options="applicantTypes"
                          :allowDeselect="false"
                          :class="{ 'is-invalid': errors.has('applicantType')  }"
                          :searchable="false"/>
                <input type="hidden" name="applicantType" v-model="applicantType"
                       v-validate="'required|allowedApplicantType'"/>
                <div class="applicant_type-error animated fadeIn" v-if="errors.has('applicantType')">
                    {{ trans("common.error") }}
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="referralCode">{{ trans('order.referral-code') }}</label>
            <div class="input-group">
                <div class="input-group-prepend"><span class="input-group-text" id="inputGroupPrepend"><i
                    class="fas fa-handshake"></i></span></div>
                <input name="referralCode" type="text" id="referralCode" class="form-control"
                       v-model="referralCode"
                       v-validate="'min:3|max:32'"
                       :class="{ 'is-invalid': errors.has('referralCode')  }"
                       :placeholder="'code'">
                <div class="invalid-feedback animated fadeIn" v-if="errors.has('referralCode')">
                    {{ trans('common.validation-referral-code') }}
                </div>
                <!--<small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
            </div>
        </div>
    </form>
</template>


<script>
import auth from './../app/auth'
import vSelect from '../vendor/vue-select/Select.vue'

export default {
    name: 'order-email',
    props: ['defaultEmail', 'defaultReferralCode', 'defaultApplicantType', 'defaultPhone', 'countries', 'countriesRiskCodes', 'applicantTypes', 'allowUrl'],
    components: {vSelect},
    inject: ['trans'],
    data() {
        return {
            email: null,
            referralCode: null,
            phone: null,
            country: null,
            countryCode: null,
            validCountry: false,
            applicantType: null,
            customerRejected: null,
        }
    },
    created() {
        this.email = this.defaultEmail || auth.email
        this.phone = this.defaultPhone || auth.phone
        this.referralCode = this.defaultReferralCode
        this.applicantType = this.applicantTypes.find(item => item.value === this.defaultApplicantType)
        this.$validator.extend('allowedCountry', {
            getMessage: (field, params, data) => 'The ' + field + ' value is not valid.',
            validate: (value, args) => {
                // console.info('validate country', value);
                if (!value) return true
                return !this.countriesRiskCodes.includes(value)
            }
        })
        this.$validator.extend('allowedApplicantType', {
            getMessage: (field, params, data) => 'The ' + field + ' value is not valid.',
            validate: (applicantType, args) => {
                // console.info('validate applicant type', applicantType.value);
                if (!applicantType) return true
                return this.applicantTypes.find(item => item.value === applicantType.value) !== undefined
            }
        })
    },
    computed: {
        isRiskyCountry() {
            return this.countriesRiskCodes.includes(this.country)
        }
    },
    methods: {
        async validate() {
            let isValid = await this.$validator.validateAll()
            if (this.phone) {

            }
            if (isValid) {
                isValid = await this.checkCustomerAllowed()
            }
            this.$emit('on-validate', this.$data, isValid)
            return isValid
        },
        onCountryChange(country) {
            this.countryCode = country.value || null
        },
        onApplicantTypeChange(applicantType) {
            this.applicantType = applicantType
            this.$root.$emit('update:applicantType', this.applicantType.value)
        },
        async checkCustomerAllowed() {
            const that = this
            return new Promise(resolve => {
                laravel.ajax.send({
                    method: 'GET',
                    url: this.allowUrl,
                    data: {
                        email: this.email,
                    },
                    success: function (payload) {
                        // console.info('checkCustomerAllowed payload', payload);
                        const allowed = payload.allowed;
                        that.customerRejected = allowed ? null : payload
                        resolve(allowed)
                    },
                    error: function (e) {
                        self.errorMessage = laravel.errors.singleErrorMessage(e)
                        resolve(false)
                    }
                })
            })
        }
    },
    watch: {
        email: function (fresh) {
            this.$root.$emit('update:email', fresh)
        },
        phone: function (fresh) {
            // phone without spaces
            const phone = fresh.replace(/\s/g, '')
            this.$root.$emit('update:phone', phone)
        },
        referralCode: function (fresh) {
            this.$root.$emit('update:referralCode', fresh)
        }
    }


}
</script>


<style lang="scss" scoped>

.country-select {
    width: auto;
    width: calc(100% - 43px);
}

.country-validation-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.applicant_type-select {
    width: auto;
    width: calc(100% - 43px);
}

.applicant_type-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.order-customer__rejected {
    color: #dc3545;
    border: 1px solid #dc3545;
    background-color: #ffefef;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}
</style>
