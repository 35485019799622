import prevent from './preventDefault'
import Currency from "./Currency"

let header = {

    /** @type {Array<Currency>} */
    currencies: [],

    /** @type {Currency} */
    activeCurrency: null,
    onCryptoChangedListeners: [],
    onCurrencyChangedListeners: [],
    aboutCryptoCarousel: null,

    initExchangeCurrencies: (options) => {
        // console.info('header.initExchangeCurrencies', header)
        const $header = $('.header-exchange-currencies')

        header.currencies = options.currencies.map(
            cur => {
                const elHeader = $header.find('.currency[data-id="' + cur.id + '"]')[0] || null
                return new Currency(cur.id, cur.symbol, cur.name, cur.isCrypto, elHeader)
            }
        );

        header.aboutCryptoCarousel = $('#carouselACIndicators').carousel({})

        header.onCurrencyChangedListeners.push(header.updateActiveState)
        header.onCryptoChangedListeners.push(header.updateCurrencyInfoCarousel)

        header.currencies.filter(c => c.elHeader).forEach(currency => {
            currency.elHeader.addEventListener('click', (e) => {
                // console.info('header currency clicked', currency)
                header.activateCurrency(currency)
                app.analytics.trackGAEvent('header-currency', 'click', currency.id)
                return prevent(e);
            })
        })

        let defaultCurrency = header.currencies.find(currency => currency.isActive) || null
        if (defaultCurrency != null) {
            header.updateActiveState(defaultCurrency)
        }

    },
    /**
     * @param {Currency} currency
     */
    updateCurrencyInfoCarousel: (currency) => {
        //change about currency carousel
        let slides = header.aboutCryptoCarousel.find(".carousel-item[data-currency='" + currency.id + "']")
        if (slides.length && slides[0].dataset) {
            const slideIndex = parseInt(slides[0].dataset.index)
            header.aboutCryptoCarousel.carousel(slideIndex)
        } else {
            // console.error('updateCurrencyInfoCarousel - slide not found for currency', currency);
        }
    },
    /**
     * @param {Currency} currency
     */
    updateActiveState: (currency) => {
        for (let curr of header.currencies) {
            let $curr = $(curr.elHeader)
            let $img = $curr.find('img').first();
            if (curr === currency) {
                curr.isActive = true
                $img.attr('src', $img.data('src-active'))
            } else {
                curr.isActive = false
                $img.attr('src', $img.data('src'))
            }
        }
    },
    /**
     * @param {Currency|string} currency
     */
    activateCurrency(currency) {
        if (!header.currencies.length) {
            return;
        }
        if (typeof currency === 'string') {
            currency = header.currencies.find(c => c.id === currency)
        }
        if (!currency) {
            // there is no currency with this id in promoted currencies
            return
        }
        header.activeCurrency = currency
        // console.info('header activateCurrency ',activeCurrency);
        for (let handler of header.onCurrencyChangedListeners) {
            handler(header.activeCurrency)
        }
        if (header.activeCurrency.isCrypto) {
            for (let handler of header.onCryptoChangedListeners) {
                handler(header.activeCurrency)
            }
        }
    },

}

export default header;
