<template>
    <div id="order-confirmation" class="card shadow-lg">

        <div class="card-header">
            <h1 class="card-title">{{ trans('order.confirmation-title') }}</h1>
        </div>
        <div class="card-body">

            <form-wizard @on-complete="onComplete" @on-error="onError" @on-change="onStepChange"
                         :title="trans('order.confirmation-title')" subtitle=""
                         :color="color"
                         errorColor="#dc3545"
                         :stepSize="stepSize"
                         shape="tab"
                         ref="wizard"
                         v-if="!completed"
            >
                <wizard-step
                    slot-scope="props"
                    slot="step"
                    :tab="props.tab"
                    :transition="props.transition"
                    :index="props.index"
                    @click.native="$refs['wizard'].navigateToTab(props.index)"
                >
                    <div slot="foo-active-step"
                         style="display: flex; justify-content:center; align-items: center;">
                        <!--<i class="far fa-credit-card" v-if="props.index===orderedTabs.indexOf('txId')"></i>-->
                        <img v-bind:src="currencyIcon" alt="" v-if="props.index===orderedTabs.indexOf('txId')"/>
                        <i class="fas fa-user-circle" v-else-if="props.index===orderedTabs.indexOf('user')"></i>
                        <i class="fas fa-address-card" v-else-if="props.index===orderedTabs.indexOf('sumsub')"></i>
                        <i class="fas fa-address-card" v-else-if="props.index===orderedTabs.indexOf('kyc')"></i>
                        <i class="fas fa-address-card" v-else-if="props.index===orderedTabs.indexOf('veriff')"></i>
                        <i class="fas fa-money-check-alt" v-else-if="props.index===orderedTabs.indexOf('accounts')"></i>
                        <i class="fas fa-clipboard-check" v-else></i>
                    </div>
                    <div slot="active-step" v-if="props.index===orderedTabs.indexOf('txId')">
                        <div class="wizard-icon-wrapper"><img v-bind:src="currencyIcon" alt=""/></div>
                    </div>
                </wizard-step>


                <tab-content :title="trans('common.user')" :before-change="() => validateStep('user')"
                             v-if="allowRegistration" key="user" icon="fas fa-user-circle">
                    <order-user ref="user"
                                :user="order.user"
                                :loggedEmail="loggedEmail"/>
                </tab-content>

                <tab-content v-if="requireKyc" title="KYC" :before-change="() => validateStep('kyc')"
                             key="kyc" icon="fas fa-address-card">
                    <kyc-form
                        ref="kyc"
                        :typeGroups="kycTypes"
                        :token="order.token"
                        :kyc-threshold="order.kyc_threshold"
                        :init-data="initKycData"
                    />
                </tab-content>

                <tab-content v-if="requireVeriff" title="KYC" :before-change="() => validateStep('veriff')"
                             key="veriff" icon="fas fa-address-card">
                    <kyc-veriff
                        ref="veriff"
                        :veriff="veriff"
                        :order="order"
                        :init-kyc="initKycData"
                        :kyc-threshold="order.kyc_threshold"
                        @finished="onVeriffFinished"
                    />
                </tab-content>

                <tab-content v-if="requireSumsub" title="KYC" :before-change="() => validateStep('sumsub')"
                             key="sumsub" icon="fas fa-address-card">
                    <kyc-sumsub
                        ref="sumsub"
                        :sumsub="sumsub"
                        :order="order"
                        :init-kyc="initKycData"
                        :kyc-threshold="order.kyc_threshold"
                        @finished="onSumsubFinished"
                    />
                </tab-content>

                <tab-content v-if="order.need_tx_id" :title="trans('common.payment')"
                             :before-change="() => validateStep('txId')" key="tx_id" icon="far fa-credit-card">
                    <order-tx-id ref="txId"
                                 :currency="currency"
                                 :defaultTxId="tx_id"
                                 :payment-info="order.payment_info"
                    />
                </tab-content>

                <tab-content :title="trans('common.accounts')" :before-change="() => validateStep('accounts')"
                             key="accounts" icon="fas fa-money-check-alt">
                    <accounts-step ref="accounts"
                                   :user="order.user"
                                   :verification-required="order.need_account_verification"
                                   :kyc-threshold="order.kyc_threshold"/>
                </tab-content>

                <template slot="footer" slot-scope="props">
                    <button type="button" class="btn btn-secondary d-none d-sm-inline-block" key="back"
                            @click="props.prevTab()"
                            v-if="props.activeTabIndex > 0 ">
                        <i class="fas fa-angle-left"></i> {{ trans('common.back') }}
                    </button>
                    <button-with-spinner type="button" btn-class="btn btn-primary pull-right" key="continue"
                                         @click="props.nextTab()"
                                         v-if="!props.isLastStep"
                                         :label="trans('common.continue')"
                                         active-icon-class="fas fa-angle-right"
                                         :left-side="false"
                                         :loading="isLoading"/>
                    <button-with-spinner type="button" btn-class="btn btn-primary pull-right" key="complete"
                                         @click="props.nextTab()"
                                         v-if="props.isLastStep"
                                         :label="trans('order.confirm-order-submit')"
                                         active-icon-class="fas fa-handshake"
                                         :left-side="false"
                                         :loading="isLoading"/>
                </template>

                <zoom-y-transition :duration="500">
                    <div class="alert alert-danger" role="alert" v-if="errorMessage != false">
                        <i class="far fa-frown"></i> {{ errorMessage }}
                    </div>
                </zoom-y-transition>

            </form-wizard>

            <div v-if="completed">
                <div class="row p-3">
                    <div class="col-md-4 col-lg-3">
                        <lottie v-if="completed"
                                :options="animOptions"
                                :height="200"
                        />
                    </div>
                    <div class="col-md-8 col-lg-9">
                        <h3 class="pb-3">{{ trans('order.confirmation-completed-title') }}</h3>
                        <p v-html="trans('order.confirmation-completed-info')"></p>
                        <p>{{ trans('common.your-order-id') }}: <span class="order-id">{{ order.id }}</span></p>
                        <p>{{ trans('common.you-can-close-this-page') }}</p>
                    </div>

                </div>

            </div>

            <div v-if="debug" class="card-body p-5">
                <hr>
                <h3>DEBUG</h3>
                <div>tabIndexTxId {{ orderedTabs.indexOf('txId') }}
                    - <strong>need txId</strong> : {{ order.need_tx_idl }}
                </div>
                <div>tabIndexUser {{ orderedTabs.indexOf('user') }}
                    - <strong>allowRegistration</strong>: {{ allowRegistration }}
                </div>
                <div>tabIndexKyc {{ orderedTabs.indexOf('kyc') }}
                    - <strong>requireKyc</strong>: {{ requireKyc }}
                </div>
                <div>tabIndexVeriff {{ orderedTabs.indexOf('veriff') }}
                    - <strong>requireVeriff</strong>: {{ requireVeriff }}
                </div>
                <div>tabIndexSumsub {{ orderedTabs.indexOf('sumsub') }}
                    - <strong>requireSumsub</strong>: {{ requireSumsub }}
                </div>
                <div>tabIndexAccounts {{ orderedTabs.indexOf('accounts') }}
                    - <strong>requireAccountVerification</strong>: {{ requireAccountVerification }}
                </div>
                <div>txId <code>{{ tx_id }}</code></div>
                <div>requireAccountVerification {{ order.need_account_verification }}</div>
                <div>Registration {{ registrationPassword ? '•••••••' : registrationPassword }}</div>
                <div>currencyIcon: {{ currencyIcon }}</div>
            </div>

        </div>
    </div>
</template>

<script>
// import Vue from 'vue'
// import debounce from 'debounce'
import translator from '../app/translator'
import auth from '../app/auth'
import TabContent from './../vendor/vue-form-wizard/components/TabContent.vue'
import WizardStep from './../vendor/vue-form-wizard/components/WizardStep.vue'
import FormWizard from './../vendor/vue-form-wizard/components/FormWizard.vue'
import Lottie from 'vue-lottie'
import animationSuccess from "../vendor/animation_todos";
import {ZoomYTransition} from 'vue2-transitions'

import ButtonWithSpinner from "./ButtonWithSpinner.vue";
import OrderTxId from "./OrderTxId.vue";
import KycForm from "./KycForm.vue";
import AccountsStep from "./AccountsStep.vue";
import OrderUser from "./OrderUser.vue";
import KycVeriff from "./KycVeriff.vue";
import KycSumsub from "./kycSumsub.vue";

const TYPE_BUY = 1;
const TYPE_SELL = 2;

export default {
    name: 'order-confirmation',
    props: ['order', 'kycTypes', 'initKycData', 'veriff', 'sumsub', 'confirmOrderUrl'],
    data() {
        return {
            debug: false,
            stepSize: 'sm',
            activeIndex: 0,
            // color: '#007bff',
            color: '#5E57EC',
            // color: '#777',
            errorMessage: false,
            loading: false,
            translator: translator,
            isMounted: false,
            completed: false,
            animOptions: {animationData: animationSuccess, loop: false},
            tx_id: null,
            veriffResponse: null,
            sumsubResponse: null,
            kycData: {},
        }
    },
    components: {
        KycVeriff,
        KycSumsub,
        OrderTxId, KycForm, OrderUser, AccountsStep,
        FormWizard, TabContent, WizardStep,
        ZoomYTransition, ButtonWithSpinner, Lottie
    },
    provide() {
        return {trans: this.trans}
    },
    computed: {
        orderedTabs() {
            let tabs = [];

            if (this.allowRegistration) {
                tabs.push('user');
            }
            if (this.requireKyc) {
                tabs.push('kyc');
            }
            if (this.requireSumsub) {
                tabs.push('sumsub');
            }
            if (this.requireVeriff) {
                tabs.push('veriff');
            }
            if (this.order.need_tx_id) {
                tabs.push('txId');
            }
            if (this.requireAccountVerification || true) {
                tabs.push('accounts');
            }
            return tabs;
        },
        isCrypto() {
            return this.order.type = TYPE_SELL
        },
        isLoading() {
            if (!this.isMounted) {
                return true;
            }
            if (this.loading) {
                return true;
            }
            return (this.$refs.wizard && this.$refs.wizard.loading);
        },
        currency() {
            return this.order.market.market_currency
        },
        currencyIcon() {
            let icon = this.currency.id.toLowerCase();
            //return `/front/crypto/black/${icon}.svg`;
            return `/front/crypto/black/${icon}.svg`;
        },
        allowRegistration() {
            return !this.order.user.is_registered
        },
        requireAccountVerification() {
            return this.order.need_account_verification
        },
        requireKyc() {
            return this.order.need_kyc
        },
        requireVeriff() {
            return this.order.need_veriff
        },
        requireSumsub() {
            return this.order.need_sumsub && !this.requireVeriff
        },
        loggedEmail() {
            return auth.email;
        },
        registrationPassword() {
            if (this.isMounted && this.allowRegistration) {
                if (this.$refs.user.register) {
                    return this.$refs.user.password
                }
            }
            return false
        }
    }
    ,
    created() {
        // console.info('orderConfirmation created ');
        // console.info(this.order);
        // console.info(this.kycTypes);
        // console.info(this.veriff);
        // console.info(this.sumsub);
        this.tx_id = this.order.tx_id
        this.kycData = this.initKycData
    }
    ,
    mounted() {
        let self = this
        // self.$root.$on('update:orderType', function (orderType) {   })
        self.isMounted = true;
        self.$root.$on('update:tx_id', function (val) {
            self.tx_id = val
        })
        self.$root.$on('kycField:update', function (type, val) {
            self.kycData[type.name] = val
        })

    },
    methods: {
        validateStep(step) {
            if (!this.isMounted) {
                return false
            }
            let self = this
            // console.info('validateStep',step);
            app.analytics.trackGAEvent('order-confirmation', 'step-validation', step)
            return self.$refs[step].validate()
        },
        onComplete() {
            let self = this;
            self.loading = true //shared loader

            let data = {}
            if (self.tx_id) {
                data.tx_id = self.tx_id
            }
            if (self.registrationPassword) {
                data.registration_password = self.registrationPassword
            }
            if (self.veriffResponse) {
                data.veriff_response = self.veriffResponse
            }
            laravel.ajax.send({
                method: 'POST',
                url: this.confirmOrderUrl,
                data: data,
                success: function (p) {
                    app.analytics.trackGAEvent('order-confirmation', 'submit', 'orderId ' + self.order.id)
                    self.completed = true
                    self.loading = false;
                    self.$el.scrollIntoView(true)
                },
                error: function (e) {
                    self.errorMessage = laravel.errors.singleErrorMessage(e);
                    app.analytics.trackGAEvent('order-confirmation', 'error', self.errorMessage)
                    self.loading = false
                }
            })
        }, onError(error) {
            if (error != null) {
                this.errorMessage = error
            } else {
                this.errorMessage = false;
            }
        },
        onStepChange(prev, next) {
            this.$root.$emit('kycFormShown', next === this.tabIndexKyc)
        },
        trans(term) {
            return this.translator.trans(term)
        },
        onVeriffFinished(response) {
            this.veriffResponse = response
        },
        onSumsubFinished(response) {
            this.sumsubResponse = response
        }
    }

}
</script>

<style lang="scss" scoped>

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$dexblue: #97cdd9;
$dexblueColor: #16b5d9;
$gray: #ebebeb;
$red: #dc3545 !default;
$blue: #007bff !default;
$card-cap-bg: #e8e8e8;

#order-confirmation {

    .card-header {
        border-bottom: 0;
        background-color: $card-cap-bg;
    }

    .card-title {
        text-align: center;
        @media (max-width: 400px) {
            font-size: 1.3rem;
        }
    }

    border-radius: 17px;

    .card-body {
        padding: 0;
        @media (max-width: 400px) {
            /*padding: 1.25rem 0;*/

        }
    }


    #order-calculator, .order-form-content {
        /*background-color: $gray; */
        /*background-color: #FFF;*/
        /*border: 0;*/
        /*border-radius: 10px;*/
        /*border-top: 1px $gray-300 solid; */
        border-radius: 0;
        color: #000;
        padding: 1rem;
        margin-top: 1rem;

        code {
            /* color:$blue; */
            font-weight: bold;
        }

        @media (max-width: 400px) {
            margin: 8px;

        }
    }

    .order-id {
        font-family: monospace, serif;
        font-size: 2rem;
        display: inline-block;
        border: 1px solid $dexblueColor;
        border-radius: 15px;
        padding: 0.25rem 0.5rem;

    }
}
</style>
